import {Component, NgZone, OnInit} from '@angular/core';
import {
  FormItemType,
  Intervals, JsonForm,
  JsonFormItem, JsonFormSection,
  JsonProtocol,
  JsonProtocolItem,
  JsonProtocolPicture,
  JsonTask,
  ProtocolResult,
  RuebInterval
} from 'rueb-data-model';
import {JsonProtocolNote} from 'rueb-data-model/src/json-protocol-note';
import {ActivatedRoute} from '@angular/router';
import {FormService} from '../../services/form.service';
import {ProtocolService} from '../../services/protocol.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {StructureService} from '../../services/strcuture-service';
import {FileDataService} from '../../services/file-data.service';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {PROTOCOL_ID_ROUTE_PARAM, STRUCTURE_ID_ROUTE_PARAM} from '../../app/route-parameters';
import {forEach} from '@angular/router/src/utils/collection';

class PictureVM {
  picture: JsonProtocolPicture;
  pictureData: SafeUrl;
  expanded: boolean;

  constructor(picture: JsonProtocolPicture) {
    this.picture = picture;
    this.expanded = false;
  }
}

class ProtocolNoteVM {
  note: JsonProtocolNote;
  pictureVMs: PictureVM[] = [];


  constructor(note: JsonProtocolNote) {
    this.note = note;
  }
}


@Component({
  selector: 'app-page-show-protocol',
  templateUrl: 'show-protocol.html',
  styleUrls: ['show-protocol.css'],
})
export class ShowProtocolComponent implements OnInit {

  readonly itemType = FormItemType;
  readonly resultType = ProtocolResult;
  protocol: JsonProtocol;
  values: Map<string, JsonProtocolItem> = new Map<string, JsonProtocolItem>();
  notes: Map<string, ProtocolNoteVM> = new Map<string, ProtocolNoteVM>();
  title: string;
  structureName: Observable<string>;
  form: BehaviorSubject<JsonForm> = new BehaviorSubject(null);

  private protocolId: string;

  constructor(private route: ActivatedRoute,
              private formService: FormService,
              private protocolService: ProtocolService,
              private fileDataService: FileDataService,
              private ngZone: NgZone,
              private structService: StructureService,
              private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.structureName = this.route.paramMap.flatMap(aParams => this.structService.getById(aParams.get(STRUCTURE_ID_ROUTE_PARAM)))
      .map(aStructure => aStructure.name);
    const activatedRoute = this.route.snapshot;
    this.protocolId = activatedRoute.params[PROTOCOL_ID_ROUTE_PARAM];
    const structId: string = activatedRoute.params[STRUCTURE_ID_ROUTE_PARAM];

    this.ngZone.run(() => {
      this.protocolService.load(this.protocolId, structId).subscribe(aProtocol => {
        this.protocol = aProtocol;
        this.formService.load(this.protocol.formId, structId).subscribe(aForm => {
          this.title = `${aForm ? aForm.name : this.protocol.formName} ${this.protocol.createdDate.toFormat('DD')}`;
          this.form.next(aForm);
        });
        const newValues: Map<string, JsonProtocolItem> = new Map<string, JsonProtocolItem>();
        for (const curProtocolItem of this.protocol.values) {
          newValues.set(curProtocolItem.formItem.id, curProtocolItem);
          if (curProtocolItem.noteId != null) {
            const protocolNote = curProtocolItem.note;
            if (protocolNote != null) {
              const protocolNoteVM = new ProtocolNoteVM(protocolNote);
              this.notes.set(curProtocolItem.formItem.id, protocolNoteVM);
              for (const curPicture of protocolNote.pictures) {
                const pictureVm = new PictureVM(curPicture);
                this.fileDataService.get(curPicture.pictureId).subscribe(aFileData => {
                  // sanitize since we know where the data comes from
                  pictureVm.pictureData = aFileData.data;
                  protocolNoteVM.pictureVMs.push(pictureVm);
                });
              }
            } else {
              console.warn(`Protocol note |${curProtocolItem.noteId}| not available, but expected`);
            }
          }
        }
        this.values = newValues;
      });
    });
  }

  asTask(item: JsonFormItem): JsonTask {
    return item as JsonTask;
  }

  getHeading(item: JsonFormItem): Observable<string> {
    const result = this.asTask(item).heading;
    if (result) {
      return new BehaviorSubject(result);
    } else {
      return this.form.map(form => {
        if (form == null) {
          return '';
        }
        const tmp = this.recursiveFind(item.id, form.formItems);
        const task = tmp as JsonTask;
        if (task !== undefined) {
          return task.heading;
        } else {
          return 'undefined';
        }
      });
    }
  }

  recursiveFind(id: string, formItem: JsonFormItem[]): JsonFormItem {
    if (formItem == null) {
      return null;
    }
    for (const item of formItem) {
      if (id === item.id) {
        return item;
      } else if (item instanceof JsonFormSection) {
        const subsection = item as JsonFormSection;
        const result = this.recursiveFind(id, subsection.subItems);
        if (result != null) {
          return result;
        }
      }
    }
    return null;
  }

  getIntervalFor(aTask: JsonTask): RuebInterval {
    if (aTask == null) {
      throw new Error('Task may not be null');
    }

    return aTask.interval ? Intervals.intervalByValue(aTask.interval) : null;
  }
}
