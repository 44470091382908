import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormItemType, JsonFormItem, JsonFormSection, JsonTask, MetaInfo, MetaType, Utilities} from 'rueb-data-model';
import {Observable} from 'rxjs';
import {FormEditService} from '../form-edit-service/form-edit.service';
import {JsonDueDateInfo} from 'rueb-data-model/src/json-due-date-info';
import {CreateEvent} from '../edit-component/edit-component';
import {EditTaskModal, EditTaskModalResult} from '../edit-task-modal/edit-task-modal';
import {SuiModalService} from 'ng2-semantic-ui';
import {FormService} from '../../../services/form.service';
import {AlertModal} from '../../../modals/confirm-modal';

@Component({
  selector: 'app-form-item-component',
  templateUrl: 'form-item-component.html',
  styleUrls: ['form-item-component.css'],
})
export class FormItemComponent {
  readonly itemType = FormItemType;

  @Input()
  formItem: JsonFormItem;
  @Input()
  targetItemId: string;
  @Input()
  editable: boolean;

  @Output()
  delete: EventEmitter<any> = new EventEmitter();
  @Output()
  moveUp: EventEmitter<any> = new EventEmitter();
  @Output()
  moveDown: EventEmitter<any> = new EventEmitter();

  myEdit: boolean;

  myIndentDepth: number;

  constructor(private formEditService: FormEditService,
              private modalService: SuiModalService,
              private formService: FormService) {
  }

  asTask(aFormItem: JsonFormItem) {
    return aFormItem as JsonTask;
  }

  asSection(aFormItem: JsonFormItem): JsonFormSection {
    return aFormItem as JsonFormSection;
  }

  onDeleteItem(aFormItem: JsonFormItem) {
    console.log('delete : ' + aFormItem.name + ' with id: ' + aFormItem.id);
    const arrayToDeleteFrom: JsonFormItem[] = this.asSection(this.formItem).subItems;
    const index: number = arrayToDeleteFrom.findIndex(curFormItem => curFormItem.id === aFormItem.id);

    if (index > -1) {
      console.log('delete index: ' + index);
      arrayToDeleteFrom.splice(index, 1);
    } else {
      throw new Error(`Could not delete form item with id ${aFormItem.id} from section ${this.formItem.id}`);
    }
  }

  onMoveUpItem(aFormItem: JsonFormItem) {
    const arrayToSwap: JsonFormItem[] = this.asSection(this.formItem).subItems;

    const index: number = arrayToSwap.findIndex(i => i.id === aFormItem.id);
    if (index > 0 && index < arrayToSwap.length) { // may not be the first index
      Utilities.swap(arrayToSwap, index, index - 1);
    }
  }

  onMoveDownItem(aFormItem: JsonFormItem) {
    const arrayToSwap: JsonFormItem[] = this.asSection(this.formItem).subItems;

    const index = arrayToSwap.findIndex(i => i.id === aFormItem.id);
    if (index > -1 && index < arrayToSwap.length - 1) { // may not be the last index
      Utilities.swap(arrayToSwap, index, index + 1);
    }
  }

  onEdit() {
    switch (this.formItem.metaInfo.type) {
      case MetaType.SECTION:
        this.myEdit = true;
        break;
      case MetaType.TASK:
        if (this.formItem.type === FormItemType.TEXT_AREA) {
          this.myEdit = true;
        } else {
          const task = this.asTask(this.formItem);
          this.showEditTaskFor({aTask: task, aEditing: true}).subscribe(aResult => {
            console.info(`Edited task ${aResult.task}`);
          });
        }
        break;
      default:
        console.warn('Cannot edit unknown formitem of type |' + this.formItem.metaInfo.type + '|');
    }
  }

  onDelete() {
    this.modalService.open(new AlertModal('Löschen', `Möchten Sie den Eintrag ${this.formItem.name} wirklich löschen?`)).onApprove(() => {
      this.delete.emit({});
    });
  }

  onMoveUp() {
    this.moveUp.emit({});
  }

  onMoveDown() {
    this.moveDown.emit({});
  }

  onCreate(aCreateEvent: CreateEvent) {
    const itemType: FormItemType = aCreateEvent.formItemType;
    switch (itemType) {
      case FormItemType.HEADING:
        this.addSection();
        break;
      case FormItemType.CHECKBOX:
        this.addCheckbox();
        break;
      case FormItemType.COUNTER:
        if (aCreateEvent.buildDifference) {
          this.addCounter();
        } else {
          this.addInstantaneousCounter();
        }
        break;
      case FormItemType.TEXT_AREA:
        this.addTextarea();
        break;
      case FormItemType.TEXT_FIELD:
        throw new Error(`Form item type ${itemType} not supported`);
    }
  }

  labelChanged(newLabel: string) {
    this.formItem.name = newLabel;
    this.myEdit = false;
  }

  addSection(): void {
    const newSection: JsonFormSection = JsonFormSection.create('', [], this.formEditService.generateTaskId());
    this.asSection(this.formItem).subItems.push(newSection);
  }

  addCheckbox(): void {
    const newTask: JsonTask = JsonTask.create('', 30, FormItemType.CHECKBOX, this.formEditService.generateTaskId());
    const newDueDate: JsonDueDateInfo = new JsonDueDateInfo();
    newDueDate.metaInfo = MetaInfo.create(MetaType.DUE_DATE_INFO);
    newDueDate.taskId = newTask.id;
    this.showEditTaskFor({aTask: newTask}).subscribe(aResult => {
      this.doAddNewTask(aResult.task);
    });
  }

  addTextarea(): void {
    const newTask: JsonTask = JsonTask.create('', 30, FormItemType.TEXT_AREA, this.formEditService.generateTaskId());
    const newDueDate: JsonDueDateInfo = new JsonDueDateInfo();
    newDueDate.metaInfo = MetaInfo.create(MetaType.DUE_DATE_INFO);
    newDueDate.taskId = newTask.id;

    this.doAddNewTask(newTask);
  }

  addCounter(): void {
    const aCounter: JsonTask = JsonTask.create('', 30, FormItemType.COUNTER, this.formEditService.generateTaskId(), 'm³', 'Verbrauch',
      true);
    this.doAddCounter(aCounter);
  }

  addInstantaneousCounter() {
    const aCounter: JsonTask = JsonTask.create('', 30, FormItemType.COUNTER, this.formEditService.generateTaskId(), 'A', '', false);
    this.doAddCounter(aCounter);
  }

  private doAddCounter(aCounter: JsonTask): void {
    console.debug(aCounter);
    const newDueDate: JsonDueDateInfo = new JsonDueDateInfo();
    newDueDate.metaInfo = MetaInfo.create(MetaType.DUE_DATE_INFO);
    newDueDate.taskId = aCounter.id;
    this.showEditTaskFor({aTask: aCounter}).subscribe(aResult => {
      this.doAddNewTask(aResult.task);
    });
  }

  private doAddNewTask(aTask: JsonTask): void {
    this.asSection(this.formItem).subItems.push(aTask);
  }

  private showEditTaskFor({aTask, aEditing = false}:
                            { aTask: JsonTask, aEditing?: boolean }): Observable<EditTaskModalResult> {
    if (aTask.type === FormItemType.COUNTER) {
      console.debug('Editing counter. Fetching heading suggestions...');
      const headingSuggestions = this.formService.getHeadingSuggestions();
      return headingSuggestions.flatMap(aSuggestions => {
        console.debug('Fetched heading suggestions', aSuggestions);
        return this.doShowEditTaskFor({aTask: aTask, aEditing: aEditing, aSuggestions: aSuggestions});
      });
    } else {
      return this.doShowEditTaskFor({aTask: aTask, aEditing: aEditing});
    }
  }

  private doShowEditTaskFor({aTask, aEditing = false, aSuggestions = []}:
                              { aTask: JsonTask, aEditing?: boolean, aSuggestions?: string[] }): Observable<EditTaskModalResult> {
    return Observable.create(aObserver => {
      this.modalService.open(new EditTaskModal(aTask, aEditing, aSuggestions))
        .onApprove((result: EditTaskModalResult) => {
          aObserver.next(result);
          aObserver.complete();
        });
    });
  }
}

