import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TaskRowVm} from './task-row-vm';
import {LazyLoadEvent} from 'primeng/api';
import {TimetrackingService} from '../timetracking-service/timetracking.service';
import {JsonStructure, JsonTimetrackingTask, JsonUser, Utilities} from 'rueb-data-model';
import {DateTime} from 'luxon';
import {FormControl} from '@angular/forms';
import {PRIME_NG_CALENDAR_GERMAN_LOCALE} from '../../../utilities/prime-ng-calendar-locale';
import {Observable, Subject} from 'rxjs';
import {DataPage} from '../../../utilities/data-page';
import {AvailableUsersService} from './available-users-service';
import {AvailableStructuresService} from './available-structures-service';
import {SortType} from '../../../utilities/sorttype';
import {TimetrackingTaskEditDialogComponent} from '../timetracking-task-edit-dialog/timetracking-task-edit-dialog.component';
import {FilterValues} from './filter-values';
import {TasksRequest} from './tasks-request';
import {AlertModal} from '../../../modals/confirm-modal';
import {SuiModalService} from 'ng2-semantic-ui';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-tt-overview-table',
  templateUrl: 'overview-table.component.html',
  styleUrls: ['overview-table.component.css'],
  providers: [
    AvailableUsersService,
    AvailableStructuresService
  ]
})
export class OverviewTableComponent implements OnInit, OnDestroy {
  fromFilter: FormControl;
  toFilter: FormControl;
  usersFilter: FormControl;
  structuresFilter: FormControl;
  de = PRIME_NG_CALENDAR_GERMAN_LOCALE;
  dateFormat = 'dd.mm.yy';
  curPage: number;
  pageSize = 10;
  rows: TaskRowVm[];
  loading: boolean;
  totalRecords: number;

  exportAvailable = true;

  availableUsers: JsonUser[];
  availableStructures: JsonStructure[];

  sortOrder: SortType;

  @ViewChild('editDialog')
  editDialog: TimetrackingTaskEditDialogComponent;

  private loadTriggerSubject = new Subject<TasksRequest>();
  private loadTrigger$ = this.loadTriggerSubject.asObservable();
  private destroySubject = new Subject();

  constructor(private timetrackingService: TimetrackingService,
              private availableUsersService: AvailableUsersService,
              private availableStructuresService: AvailableStructuresService,
              private modalService: SuiModalService) {
    this.fromFilter = new FormControl();
    this.toFilter = new FormControl();
    this.usersFilter = new FormControl();
    this.structuresFilter = new FormControl();
  }

  ngOnInit(): void {
    Observable.combineLatest(this.fromFilter.valueChanges.startWith(undefined), this.toFilter.valueChanges.startWith(undefined),
      this.usersFilter.valueChanges.startWith(undefined),
      this.structuresFilter.valueChanges.startWith(undefined), (v1, v2, v3, v4) => new FilterValues(v1, v2, v3, v4))
      .map(aFilterValues => {
        const fromFilter = aFilterValues.fromFilter ? DateTime.fromJSDate(aFilterValues.fromFilter) : undefined;
        const toFilter = aFilterValues.toFilter ? DateTime.fromJSDate(aFilterValues.toFilter) : undefined;
        return new TasksRequest(0, this.pageSize, fromFilter, toFilter, aFilterValues.usersFilter,
          aFilterValues.structuresFilter);
      }).do({
      next: value => console.log('New filter value', value),
      error: err => console.error('Error waiting for filter value', err),
      complete: () => console.log('Completed waiting for filter values')
    }).subscribe(this.loadTriggerSubject);

    this.loadTrigger$.takeUntil(this.destroySubject).switchMap(aRequest => this.doLoadTimetrackingTasks(aRequest)).subscribe(aDataPage => {
      console.debug('Loaded tasks', aDataPage);
      this.curPage = aDataPage.number;
      this.rows = aDataPage.content.map(aJsonTask => this.createRowFor(aJsonTask));
      this.totalRecords = aDataPage.totalElements;
      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  loadTimetrackingTasks(aEvent: LazyLoadEvent) {
    this.pageSize = aEvent.rows;

    const requestedPage = Math.round(aEvent.first / aEvent.rows);
    const nextTasksRequest = this.constructCurrentTasksRequest();
    nextTasksRequest.requestedPage = requestedPage;
    nextTasksRequest.pageSize = aEvent.rows;
    this.loadTriggerSubject.next(nextTasksRequest);
  }

  searchUsers(aSearchEvent) {
    this.availableUsers = this.availableUsersService.searchUsers(aSearchEvent.query);
  }

  searchStructures(aSearchEvent) {
    this.availableStructures = this.availableStructuresService.searchStructures(aSearchEvent.query);
  }

  setDateSortOrder(aSortType: SortType) {
    const descending = aSortType === 'desc';
    const nextTasksRequest = this.constructCurrentTasksRequest();
    nextTasksRequest.descending = descending;
    this.loadTriggerSubject.next(nextTasksRequest);
    this.sortOrder = aSortType;
  }

  editTask(aTaskVm: TaskRowVm): void {
    this.timetrackingService.loadTimetrackingTask(aTaskVm.id).subscribe(
      {
        next: aTimetrackingTask => this.editDialog.showWith(aTimetrackingTask, this.availableUsersService.allUsers,
          this.availableStructuresService.allStructures),
        error: err => console.error(`Error loading timetracking task ${aTaskVm.id}`, err)
      }
    );
  }

  deleteTask(aTaskVm: TaskRowVm): void {
    this.modalService.open(
      new AlertModal('Wirklich löschen?', 'Möchten Sie den Task wirklich löschen? Diese Aktion kann nicht mehr rückgängig gemacht werden.'))
      .onApprove(() => {
        this.timetrackingService.deleteTimetrackingTask(aTaskVm.id).subscribe(
          {
            next: aDeletedTask => this.timetrackingTaskDeleted(aDeletedTask),
            error: err => console.error(`Error deleting task ${aTaskVm.id}`, err)
          }
        );
      });
  }

  taskEdited(aEditedTask: JsonTimetrackingTask): void {
    this.timetrackingService.saveTimetrackingTask(aEditedTask).subscribe(
      {
        next: aSavedTimetrackingTask => {
          console.info(`Successfully saved timetracking task ${aSavedTimetrackingTask.id}`);
          const tasksRequest = this.constructCurrentTasksRequest();
          this.loadTriggerSubject.next(tasksRequest);
        },
        error: err => console.error(`Error saving timetracking task ${aEditedTask}`, err)
      }
    );
  }

  createNewEntry() {
    const newTask = new JsonTimetrackingTask();
    newTask.structures = [];
    newTask.user = null; // must be null since angular reactive forms do not accept undefined values
    newTask.start = Utilities.now();
    newTask.end = Utilities.now();

    this.editDialog.showWith(newTask, this.availableUsersService.allUsers,
      this.availableStructuresService.allStructures);
  }

  export() {
    this.exportAvailable = false;
    const tasksRequest = this.constructCurrentTasksRequest();
    this.timetrackingService.exportTimetrackingTasks(tasksRequest.fromFilter, tasksRequest.endFilter, tasksRequest.usersFilter,
      tasksRequest.structuresFilter).finally(() => this.exportAvailable = true).subscribe(
      {
        next: aBlob => this.saveExport(aBlob),
        error: err => console.error('Error downloading export', err)
      });
  }

  private saveExport(aBlob: Blob): void {
    const dateString = Utilities.now().toFormat('yyyyMMddHHmmss');
    const fileName = `zeiterfassung-${dateString}.xlsx`;
    FileSaver.saveAs(aBlob, fileName);
  }

  private timetrackingTaskDeleted(aDeletedTask) {
    console.info(`Deleted task ${aDeletedTask.id}`);
    const tasksRequest = this.constructCurrentTasksRequest();
    if (this.rows.length < 2) {
      tasksRequest.requestedPage = Math.max(0, tasksRequest.requestedPage - 1);
    }
    this.loadTriggerSubject.next(tasksRequest);
  }

  private constructCurrentTasksRequest(): TasksRequest {
    const fromFilter = this.fromFilter.value ? DateTime.fromJSDate(this.fromFilter.value) : undefined;
    const toFilter = this.toFilter.value ? DateTime.fromJSDate(this.toFilter.value) : undefined;
    const usersFilter = this.usersFilter.value;
    const structuresFilter = this.structuresFilter.value;
    const descencding = this.sortOrder === 'desc';
    return new TasksRequest(this.curPage, this.pageSize, fromFilter, toFilter, usersFilter, structuresFilter, descencding);
  }

  private doLoadTimetrackingTasks(aRequest: TasksRequest): Observable<DataPage<JsonTimetrackingTask>> {
    if (aRequest == null) {
      throw new Error('Request may not be null');
    }
    if (aRequest.requestedPage == null) {
      throw new Error('Requested page may not be null');
    }
    if (aRequest.pageSize == null) {
      throw new Error('Page size may not be null');
    }
    console.debug('Loading tasks');
    this.loading = true;
    return this.timetrackingService.loadTimetrackingTasks({page: aRequest.requestedPage, size: aRequest.pageSize}, aRequest.fromFilter,
      aRequest.endFilter, aRequest.usersFilter, aRequest.structuresFilter, aRequest.descending);
  }

  private createRowFor(aTask: JsonTimetrackingTask): TaskRowVm {
    return {
      id: aTask.id,
      date: aTask.start.toLocaleString(Utilities.getDefaultDateFormat()),
      start: aTask.start.toLocaleString(DateTime.TIME_24_SIMPLE),
      end: aTask.end.toLocaleString(DateTime.TIME_24_SIMPLE),
      duration: aTask.end.diff(aTask.start).toFormat('hh:mm'),
      user: aTask.user.name,
      structures: aTask.structures.map(aStructure => aStructure.name).sort((a, b) => a.localeCompare(b))
    };
  }
}
