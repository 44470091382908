import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Session} from '../session/session';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private session: Session) {
  }

  canActivate(): boolean {
    return this.session.isAdmin;
  }
}
