import {Injectable} from '@angular/core';

@Injectable()
export class Data {
  public storage: any;
  public returnTo: any;

  public constructor() {
  }
}
