import {JsonStructure, JsonUser} from 'rueb-data-model';

export class FilterValues {
  fromFilter: Date;
  toFilter: Date;
  usersFilter: JsonUser[];
  structuresFilter: JsonStructure[];

  constructor(fromFilter: Date, toFilter: Date, usersFilter: JsonUser[], structuresFilter: JsonStructure[]) {
    this.fromFilter = fromFilter;
    this.toFilter = toFilter;
    this.usersFilter = usersFilter;
    this.structuresFilter = structuresFilter;
  }
}
