import {JsonForm, Utilities} from 'rueb-data-model';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export class FormEditService {
  private form: JsonForm;

  constructor() {
  }

  public setForm(aForm: JsonForm) {
    if (this.form) {
      throw new Error(`Form already set to ${this.form.dbId}`);
    }
    this.form = aForm;
  }

  public getForm(): Observable<JsonForm> {
    if (this.form) {
      return Observable.of(this.form);
    } else {
      return Observable.empty();
    }
  }

  public generateTaskId(): string {
    if (!this.form) {
      throw new Error('Form is not set');
    }
    return Utilities.getTaskId(this.form.dbId);
  }
}
