import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {StructureListComponent} from '../pages/structurelist/structurelist';
import {StructDetailsComponent} from '../pages/structdetails/struct-details.component';
import {FormCreatePageComponent} from '../pages/formcreate/formcreate';
import {OperatorListComponent} from '../pages/operators/operator-list.component';
import {OperatorDetailsComponent} from '../pages/operators/operator-details.component';
import {AdminComponent} from '../pages/admin/admin.component';
import {UserCreateComponent} from '../pages/admin/user-create.component';
import {LoginComponent} from '../pages/login/login.component';
import {AdminGuard} from '../providers/guards/admin-guard';
import {DisplayStructureInfoPage} from '../pages/display-structure-info/display-structure-info';
import {ShowProtocolComponent} from '../pages/show-protocol/show-protocol';
import {EntryPageComponent} from '../pages/entrypage/entry-page.component';
import {AuthGuard} from '../providers/guards/auth-guard';
import {RuebGuard} from '../providers/guards/rueb-guard';
import {
  FORM_ID_ROUTE_PARAM,
  INFO_ID_ROUTE_PARAM,
  OPERATOR_ID_ROUTE_PARAM,
  PROTOCOL_ID_ROUTE_PARAM,
  STRUCTURE_ID_ROUTE_PARAM,
  USER_ID_ROUTE_PARAM
} from './route-parameters';
import {NavOperatorListGuard} from '../navigation/navigation-guards/nav-operator-list.guard';
import {NavOperatorCreateGuard} from '../navigation/navigation-guards/nav-operator-create.guard';
import {NavOperatorDetailGuard} from '../navigation/navigation-guards/nav-operator-detail.guard';
import {NavOperatorInfoViewGuard} from '../navigation/navigation-guards/nav-operator-info-view.guard';
import {NavOperatorStructureCreateGuard} from '../navigation/navigation-guards/nav-operator-structure-create.guard';
import {NavOperatorStructureDetailGuard} from '../navigation/navigation-guards/nav-operator-structure-detail.guard';
import {NavOperatorStructureFormsCreateGuard} from '../navigation/navigation-guards/nav-operator-structure-forms-create.guard';
import {NavOperatorStructureFormsDetailGuard} from '../navigation/navigation-guards/nav-operator-structure-forms-detail.guard';
import {NavOperatorStructureProtocolsDetailGuard} from '../navigation/navigation-guards/nav-operator-structure-protocols-detail.guard';
import {NavOperatorStructureInfosViewGuard} from '../navigation/navigation-guards/nav-operator-structure-infos-view.guard';
import {NavStructuresListGuard} from '../navigation/navigation-guards/nav-structures-list.guard';
import {NavStructuresStructureDetailGuard} from '../navigation/navigation-guards/nav-structures-structure-detail.guard';
import {NavStructuresStructureFormsDetailGuard} from '../navigation/navigation-guards/nav-structures-structure-forms-detail.guard';
import {NavStructuresStructureProtocolsDetailGuard} from '../navigation/navigation-guards/nav-structures-structure-protocols-detail.guard';
import {NavStructuresStructureInfosViewGuard} from '../navigation/navigation-guards/nav-structures-structure-infos-view.guard';
import {NavUsersListGuard} from '../navigation/navigation-guards/nav-users-list.guard';
import {NavUsersCreateGuard} from '../navigation/navigation-guards/nav-users-create.guard';
import {NavUsersDetailGuard} from '../navigation/navigation-guards/nav-users-detail.guard';
import {NavClearGuard} from '../navigation/navigation-guards/nav-clear.guard';
import {OverviewTableComponent} from "../pages/timetracking/overview-table/overview-table.component";
import {NavTimetrackingOverviewGuard} from "../navigation/navigation-guards/nav-timetracking-overview.guard";

const routes: Routes = [
  {path: '', component: EntryPageComponent, pathMatch: 'full', canActivate: [AuthGuard], resolve: {navItems: NavClearGuard}},
  {
    path: 'operators',
    component: OperatorListComponent,
    canActivate: [AuthGuard, RuebGuard, AdminGuard],
    resolve: {
      navItems: NavOperatorListGuard
    }
  },
  {
    path: 'operators/create',
    component: OperatorDetailsComponent,
    canActivate: [AuthGuard, RuebGuard, AdminGuard],
    data: {create: true},
    resolve: {
      navItems: NavOperatorCreateGuard
    }
  },
  {
    path: `operators/:${OPERATOR_ID_ROUTE_PARAM}`,
    component: OperatorDetailsComponent,
    canActivate: [AuthGuard, RuebGuard, AdminGuard],
    resolve: {
      navItems: NavOperatorDetailGuard
    }
  },
  {
    path: `operators/:${OPERATOR_ID_ROUTE_PARAM}/infos/:${INFO_ID_ROUTE_PARAM}`,
    component: DisplayStructureInfoPage,
    canActivate: [AuthGuard],
    resolve: {
      navItems: NavOperatorInfoViewGuard
    }
  },
  {
    path: `operators/:${OPERATOR_ID_ROUTE_PARAM}/structures/create`,
    component: StructDetailsComponent,
    canActivate: [AuthGuard, RuebGuard],
    data: {create: true},
    resolve: {
      navItems: NavOperatorStructureCreateGuard
    }
  },
  {
    path: `operators/:${OPERATOR_ID_ROUTE_PARAM}/structures/:${STRUCTURE_ID_ROUTE_PARAM}`,
    component: StructDetailsComponent,
    canActivate: [AuthGuard, RuebGuard],
    resolve: {
      navItems: NavOperatorStructureDetailGuard
    }
  },
  {
    path: `operators/:${OPERATOR_ID_ROUTE_PARAM}/structures/:${STRUCTURE_ID_ROUTE_PARAM}/forms/create`,
    component: FormCreatePageComponent,
    canActivate: [AuthGuard, RuebGuard],
    resolve: {
      navItems: NavOperatorStructureFormsCreateGuard
    }
  },
  {
    path: `operators/:${OPERATOR_ID_ROUTE_PARAM}/structures/:${STRUCTURE_ID_ROUTE_PARAM}/forms/:${FORM_ID_ROUTE_PARAM}`,
    component: FormCreatePageComponent,
    canActivate: [AuthGuard, RuebGuard],
    resolve: {
      navItems: NavOperatorStructureFormsDetailGuard
    }
  },
  {
    path: `operators/:${OPERATOR_ID_ROUTE_PARAM}/structures/:${STRUCTURE_ID_ROUTE_PARAM}/protocols/:${PROTOCOL_ID_ROUTE_PARAM}`,
    component: ShowProtocolComponent,
    canActivate: [AuthGuard, RuebGuard],
    resolve: {
      navItems: NavOperatorStructureProtocolsDetailGuard
    }
  },
  {
    path: `operators/:${OPERATOR_ID_ROUTE_PARAM}/structures/:${STRUCTURE_ID_ROUTE_PARAM}/infos/:${INFO_ID_ROUTE_PARAM}`,
    component: DisplayStructureInfoPage,
    canActivate: [AuthGuard],
    resolve: {
      navItems: NavOperatorStructureInfosViewGuard
    }
  },
  {
    path: 'structures',
    component: StructureListComponent,
    canActivate: [AuthGuard, RuebGuard],
    resolve: {
      navItems: NavStructuresListGuard
    }
  },
  {
    path: `structures/:${STRUCTURE_ID_ROUTE_PARAM}`,
    component: StructDetailsComponent,
    canActivate: [AuthGuard, RuebGuard],
    resolve: {
      navItems: NavStructuresStructureDetailGuard
    }
  },
  {
    path: `structures/:${STRUCTURE_ID_ROUTE_PARAM}/forms/:${FORM_ID_ROUTE_PARAM}`,
    component: FormCreatePageComponent,
    canActivate: [AuthGuard, RuebGuard],
    resolve: {
      navItems: NavStructuresStructureFormsDetailGuard
    }
  },
  {
    path: `structures/:${STRUCTURE_ID_ROUTE_PARAM}/protocols/:${PROTOCOL_ID_ROUTE_PARAM}`,
    component: ShowProtocolComponent,
    canActivate: [AuthGuard, RuebGuard],
    resolve: {
      navItems: NavStructuresStructureProtocolsDetailGuard
    }
  },
  {
    path: `structures/:${STRUCTURE_ID_ROUTE_PARAM}/infos/:${INFO_ID_ROUTE_PARAM}`,
    component: DisplayStructureInfoPage,
    canActivate: [AuthGuard, RuebGuard],
    resolve: {
      navItems: NavStructuresStructureInfosViewGuard
    }
  },
  {
    path: 'users',
    component: AdminComponent,
    canActivate: [AuthGuard, RuebGuard, AdminGuard],
    resolve: {
      navItems: NavUsersListGuard
    }
  },
  {
    path: 'users/create',
    component: UserCreateComponent,
    canActivate: [AuthGuard, RuebGuard, AdminGuard],
    resolve: {
      navItems: NavUsersCreateGuard
    }
  },
  {
    path: `users/:${USER_ID_ROUTE_PARAM}`,
    component: UserCreateComponent,
    canActivate: [AuthGuard, RuebGuard, AdminGuard],
    resolve: {
      navItems: NavUsersDetailGuard
    }
  },
  {
    path: 'timetracking',
    component: OverviewTableComponent,
    canActivate: [AuthGuard, RuebGuard],
    resolve: {
      navItems: NavTimetrackingOverviewGuard
    }
  },
  {path: 'login', component: LoginComponent, resolve: {navItems: NavClearGuard}},
  {path: 'logout', component: LoginComponent, resolve: {navItems: NavClearGuard}},
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
