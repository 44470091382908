import {AfterViewInit, Component, ElementRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {ComponentModalConfig, DatepickerMode, IDatepickerLocaleValues, ModalSize, SuiModal} from 'ng2-semantic-ui';
import {JsonTask} from 'rueb-data-model/src/json-task';
import {DateTime} from 'luxon';
import {FormItemType, Intervals, RuebInterval, Utilities} from 'rueb-data-model';

export class TaskVM {
  public readonly task: JsonTask;
  public nextDue: Date;
  public stringInterval: string;
  public unit: string;
  public heading: string;

  constructor(task: JsonTask) {
    this.task = task;
    this.stringInterval = this.task.interval ? this.task.interval.toString() : '';
    if (Utilities.isValidDueDate(this.task.dueDate)) {
      this.nextDue = this.task.dueDate.toJSDate();
    } else if (this.task.interval) {
      this.nextDue = Utilities.now().toJSDate();
    }
    this.unit = this.task.unit;
    this.heading = this.task.heading;
  }
}

export interface IEditTaskModalContext {
  task: JsonTask;
  editing: boolean;
  headingSuggestions: string[];
}

@Component({
  selector: 'edit-task-modal',
  templateUrl: 'edit-task-modal.html',
  styleUrls: ['edit-task-modal.css'],
  encapsulation: ViewEncapsulation.None
})
export class EditTaskModalComponent implements AfterViewInit {
  readonly itemType = FormItemType;
  intervals: RuebInterval[];

  taskVM: TaskVM;
  pickerOverrides: Partial<IDatepickerLocaleValues> = {
    formats: {
      date: 'DD.MM.YYYY',
      datetime: 'DD.MM.YYYY, hh:mm',
      month: 'MM',
      time: 'hh:mm',
      year: 'YYYY'
    }
  };
  pickerMode = DatepickerMode.Date;
  editing: boolean;
  suggestions: string[];
  orgSuggestions: string[];

  @ViewChild('headingSearch')
  headingSearchBox: ElementRef;

  constructor(public modal: SuiModal<IEditTaskModalContext, EditTaskModalResult, void>) {
    this.taskVM = new TaskVM(modal.context.task);
    this.editing = modal.context.editing;
    this.suggestions = modal.context.headingSuggestions;
    this.intervals = Intervals.intervals();
    // this.suggestions = this.orgSuggestions.filter(
    //   aSuggestion => !this.taskVM.task.heading || aSuggestion.startsWith(this.taskVM.task.heading));
  }

  ngAfterViewInit() {
    if (this.headingSearchBox) {
      this.headingSearchBox['query'] = this.taskVM.task.heading;
      this.headingSearchBox['localeOverrides'] = {placeholder: 'Überschrift', noResults: {header: 'Keine Vorschläge', message: ''}};
    }
  }

  submitForm() {
    const result: EditTaskModalResult = {task: this.taskVM.task};
    if (result.task.id == null) {
      result.task.id = Utilities.getUUID();
    }

    result.task.unit = this.taskVM.unit;
    result.task.heading = this.taskVM.heading;

    if (result.task.type === FormItemType.COUNTER || result.task.type === FormItemType.CHECKBOX) {
      result.task.interval = this.taskVM.stringInterval ? Number(this.taskVM.stringInterval) : undefined;
      const dueDate = DateTime.fromJSDate(this.taskVM.nextDue);
      result.task.dueDate = dueDate && dueDate.isValid ? dueDate : undefined;
    } else {
      delete result.task.interval;
      delete result.task.dueDate;
    }
    this.modal.approve(result);
  }

  getHeading(): string {
    return this.editing ? 'Aufgabe bearbeiten' : 'Aufgabe anlegen';
  }

  intervalChanged(changedVM: TaskVM) {
    if (changedVM.stringInterval) {
      if (!changedVM.nextDue) {
        changedVM.nextDue = Utilities.now().toJSDate();
      }
    } else {
      changedVM.nextDue = undefined;
    }
  }

  headingChanged(aChange: Event | string) {
    console.debug('Heading changed', aChange);
    let newHeading: string;
    if (aChange instanceof Event) {
      newHeading = aChange.target['value'];
    } else {
      newHeading = aChange;
    }
    console.debug(`New Heading is ${newHeading}`);
    this.taskVM.heading = newHeading;
  }

  headingKeyHandler(aEvent: KeyboardEvent) {
    if (aEvent.keyCode === 13) {
      aEvent.preventDefault();
    }
  }
}

export class EditTaskModal extends ComponentModalConfig<IEditTaskModalContext, EditTaskModalResult, void> {
  constructor(task: JsonTask, editing: boolean, headingSuggestions: string[]) {
    super(EditTaskModalComponent, {task: task, editing: editing, headingSuggestions: headingSuggestions});
    this.isClosable = false;
    this.transitionDuration = 200;
    this.size = ModalSize.Small;
  }
}

export interface EditTaskModalResult {
  task: JsonTask;
}
