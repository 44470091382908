import {Injectable} from '@angular/core';
import {Features, JsonSingleFeature, Utilities} from 'rueb-data-model';
import {RestProvider} from '../rest/rest.provider';
import {Observable} from 'rxjs';

@Injectable()
export class FeaturesService {


  constructor(private restService: RestProvider) {
  }

  public featureAvailable(aFeature: Features): Observable<boolean> {
    return this.restService.getRemoteData({
      id: null,
      appendGet: false,
      url: `${RestProvider.FEATURE_URL}/${aFeature}`
    }).map(aJson => Utilities.fromJson(aJson, JsonSingleFeature)).map(aSingleFeature => aSingleFeature.available);
  }
}
