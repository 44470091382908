import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Utilities} from 'rueb-data-model';
import * as FileSaver from 'file-saver';
import {FileData} from '../../services/file-data.service';
import {MenuItem} from 'primeng/api';

@Component({
  selector: 'app-info-view',
  templateUrl: './info-view.component.html',
  styleUrls: ['./info-view.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoViewComponent implements OnInit, OnChanges {

  @Input()
  fileData: FileData;
  @Input()
  fileName: string;

  @Output()
  next: EventEmitter<void> = new EventEmitter();
  @Output()
  previous: EventEmitter<void> = new EventEmitter();

  items: MenuItem[];


  public dataArray: Uint8Array;
  public type: string;

  constructor() {
  }

  ngOnInit(): void {
    this.items = [
      {
        icon: 'pi pi-fw pi-arrow-left',
        command: () => this.previousInfo(),
      },
      {
        icon: 'pi pi-fw pi-arrow-right',
        command: () => this.nextInfo(),
      },
      {
        label: 'Download',
        icon: 'pi pi-fw pi-cloud-download',
        command: () => this.downloadFile(),
        styleClass: 'large-icon',
      }
    ];
  }

  ngOnChanges() {
    if (this.fileData) {
      // dirty hack to avoid data duplication
      this.dataArray = Utilities.convertDataURIToBinary(this.fileData.data['changingThisBreaksApplicationSecurity']);
      this.type = this.fileData.type;

    }
  }

  downloadFile() {
    if (this.fileName) {
      const blob = new Blob([this.dataArray], {type: this.type});
      FileSaver.saveAs(blob, this.fileName);
    }
  }

  nextInfo() {
    this.next.emit();
  }

  previousInfo() {
    this.previous.emit();
  }
}
