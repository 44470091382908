import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {StructureListDataService, StructureVM} from '../structure-list-data/structure-list-data.service';
import {JsonForm, JsonOperator, JsonProtocol, JsonStructure, Utilities} from 'rueb-data-model';
import {ActivatedRoute, Router} from '@angular/router';
import {Data} from '../../../providers/data/data.provider';
import {DateTime} from 'luxon';
import {AlertModal} from '../../../modals/confirm-modal';
import {SuiModalService} from 'ng2-semantic-ui';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-operator-structure-list',
  templateUrl: 'operator-structure-list.html',
  styleUrls: ['operator-structure-list.css']
})
export class OperatorStructureListComponent implements OnChanges {
  @Input()
  operator: JsonOperator;
  @Input()
  canEdit: boolean;

  structVms: Observable<StructureVM[]>;
  structuresReady = false;
  dueDatesReady = false;
  protocolsReady = false;

  constructor(private dataService: StructureListDataService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private data: Data,
              private modalService: SuiModalService) {

  }

  ngOnChanges(aChanges: SimpleChanges) {
    if (aChanges.operator) {
      // operator changed
      this.refresh();
    }
  }


  openForm(aStructure: StructureVM, aForm: JsonForm) {
    this.data.storage = {'structure': aStructure, 'form': aForm};
    this.router.navigate(['./', aStructure.structure.dbId, 'forms', aForm.dbId],
      {relativeTo: this.activatedRoute, fragment: aStructure.nextDueTask.id});
  }

  openProtocol(aStructure: StructureVM, aProtocol: JsonProtocol) {
    this.router.navigate(['./', aStructure.structure.dbId, 'protocols', aProtocol.dbId], {relativeTo: this.activatedRoute});
  }

  isDueDateDue(aDueDate: DateTime) {
    return Utilities.isDueDateDue(aDueDate);
  }

  delete(aStructure: JsonStructure) {
    this.modalService.open(new AlertModal('Löschen', `Möchten Sie das Bauwerk ${aStructure.name} wirklich löschen?`)).onApprove(() => {
      this.dataService.deleteStructure(aStructure).subscribe(
        val => {
          console.log('DELETE call successful value returned in body', val);
        },
        response => {
          console.log('DELETE call in error', response);
        },
        () => {
          console.log('The DELETE observable is now completed.');
          this.refresh();
        }
      );
    });
  }

  private refresh() {
    this.structuresReady = false;
    this.protocolsReady = false;
    this.dueDatesReady = false;

    if (this.operator) {
      this.dataService.loadDataFor(this.operator).subscribe(aDataAvailable => {
        if (aDataAvailable.structures && !aDataAvailable.protocols && !aDataAvailable.dueDates) {
          this.structVms = this.dataService.structVmsForOperator(this.operator)
            .map(aStructureVms => aStructureVms.sort((a, b) => a.structure.name.localeCompare(b.structure.name)));
          this.structuresReady = true;
        } else if (aDataAvailable.structures && aDataAvailable.protocols && aDataAvailable.dueDates) {
          this.structVms = this.dataService.structVmsForOperator(this.operator)
            .map(aStructureVms => aStructureVms.sort((a, b) => a.structure.name.localeCompare(b.structure.name)));
          this.protocolsReady = true;
          this.dueDatesReady = true;
        }
      });
    }
  }
}
