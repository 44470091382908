import {Injectable} from '@angular/core';
import {NavigationService} from '../navigation.service';
import {OperatorService} from '../../services/operator-service';
import {MenuItem} from 'primeng/api';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {INFO_ID_ROUTE_PARAM, OPERATOR_ID_ROUTE_PARAM, STRUCTURE_ID_ROUTE_PARAM} from '../../app/route-parameters';
import {StructureService} from '../../services/strcuture-service';

@Injectable()
export class NavOperatorStructureInfosViewGuard implements Resolve<MenuItem[]> {

  constructor(private navigationService: NavigationService,
              private operatorService: OperatorService,
              private structureService: StructureService,
  ) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MenuItem[]> | Promise<MenuItem[]> | MenuItem[] {
    const operatorId = route.paramMap.get(OPERATOR_ID_ROUTE_PARAM);
    const structureId = route.paramMap.get(STRUCTURE_ID_ROUTE_PARAM);
    const infoId = route.paramMap.get(INFO_ID_ROUTE_PARAM);

    const operator$ = this.operatorService.load(operatorId);
    const structure$ = this.structureService.getById(structureId);

    return Observable.zip(operator$, structure$, (aOperator, aStructure) => {
      const items: MenuItem[] = [
        {label: 'Betreiber', routerLink: ['/operators']},
        {label: aOperator.name, routerLink: ['/operators', aOperator.dbId]},
        {label: aStructure.name, routerLink: ['/operators', aOperator.dbId, 'structures', aStructure.dbId]},
        {
          label: 'Aktuelle Info',
          routerLink: ['/operators', aOperator.dbId, 'structures', aStructure.dbId, 'infos', infoId]
        },
      ];
      return items;
    }).do(aItems => {
      this.navigationService.clearItems();
      this.navigationService.addItems(...aItems);
    });
  }
}
