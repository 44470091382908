import {Injectable} from '@angular/core';
import {UserService} from '../../../services/user-service';
import {JsonUser} from 'rueb-data-model';

@Injectable()
export class AvailableUsersService {
  constructor(private userService: UserService) {
    userService.list().subscribe({
      next: aUsers => this._allUsers = aUsers,
      error: err => console.error('Could not load all users', err),
    });
  }

  private _allUsers: JsonUser[] = [];

  get allUsers(): JsonUser[] {
    return this._allUsers;
  }

  searchUsers(aNameQuery: string): JsonUser[] {
    return this._allUsers.filter(aUser => aUser.name.toLowerCase().includes(aNameQuery.toLowerCase()))
      .sort((a, b) => a.name.localeCompare(b.name));
  }


}
