import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SortType} from '../../../../utilities/sorttype';

@Component({
  selector: 'app-gerp-sort-button',
  templateUrl: './gerp-sort-button.component.html',
  styleUrls: ['./gerp-sort-button.component.css']
})
export class GerpSortButtonComponent implements OnInit {
  @Input() sort: SortType = null;
  @Output() sortChange = new EventEmitter<SortType>();

  constructor() {
  }

  ngOnInit() {
  }

  public setSort(sort: SortType): void {
    this.sort = sort;
    this.sortChange.emit(sort);
  }

  public toggle(): void {
    console.info('Sort: %o', this.sort);
    if (this.sort === 'asc') {
      this.setSort('desc');
    } else if (this.sort === 'desc') {
      this.setSort(null);
    } else if (this.sort == null) {
      this.setSort('asc');
    }
  }

}
