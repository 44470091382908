import {Component} from '@angular/core';
import {JsonOperator, JsonStructure, JsonStructureInfoDocumentSection, PlaceholderSections, Utilities} from 'rueb-data-model';
import {ComponentModalConfig, ModalSize, SuiModal} from 'ng2-semantic-ui';

export interface StructureCreateModalContext {
  operator: JsonOperator;
}

export interface StructureCreateModalResult {
  structure: JsonStructure;
}

export class StructureCreateModal extends ComponentModalConfig<StructureCreateModalContext, StructureCreateModalResult, void> {
  constructor(aOperator: JsonOperator) {
    super(StructureCreateModalComponent, {operator: aOperator});
    this.isClosable = false;
    this.transitionDuration = 200;
    this.size = ModalSize.Small;
  }
}

@Component({
  selector: 'app-structure-crate-modal',
  templateUrl: 'structure-crate-modal.html',
  styleUrls: ['structure-create-modal.css'],
})
export class StructureCreateModalComponent {
  structure: JsonStructure;

  constructor(public modal: SuiModal<StructureCreateModalContext, StructureCreateModalResult, void>) {
    const operator = modal.context.operator;
    this.structure = JsonStructure.empty();
    StructureCreateModalComponent.setDefaultSections(this.structure);
    this.structure.operatorId = operator.dbId;
    Utilities.ensureDbChannel(this.structure, operator.dbId);
  }

  private static setDefaultSections(aStructure: JsonStructure) {
    const picturesSection = JsonStructureInfoDocumentSection.create('Bilder');
    const picturesFromProtocolsSection = JsonStructureInfoDocumentSection.create('Bilder aus Protokollen');
    picturesFromProtocolsSection.placeholderFor = PlaceholderSections.PROTOCOL_PICTURES;
    picturesSection.subSections = [picturesFromProtocolsSection];
    aStructure.infoDocument.sections = [
      JsonStructureInfoDocumentSection.create('Pläne'),
      picturesSection,
      JsonStructureInfoDocumentSection.create('Betriebsanweisungen'),
      JsonStructureInfoDocumentSection.create('Berichte Datenauswertungen (Probebetrieb)'),
      JsonStructureInfoDocumentSection.create('Drosselprüfberichte'),
    ];
  }

  submitForm() {
    this.modal.approve({structure: this.structure});
  }
}
