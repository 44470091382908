import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {JsonFormItem} from 'rueb-data-model/src/json-form-item';
import {JsonForm} from 'rueb-data-model/src/json-form';
import {JsonStructure} from 'rueb-data-model';
import {JsonFormSection} from 'rueb-data-model/src/json-form-section';
import {Utilities} from 'rueb-data-model/src/utilities';
import {ActivatedRoute} from '@angular/router';
import {Data} from '../../providers/data/data.provider';
import {Location} from '@angular/common';
import {SuiModalService} from 'ng2-semantic-ui';
import {AlertModal} from '../../modals/confirm-modal';
import {StructureService} from '../../services/strcuture-service';
import {FormService} from '../../services/form.service';
import {Session} from '../../providers/session/session';
import {Observable, Subscription} from 'rxjs';
import {FormEditService} from './form-edit-service/form-edit.service';
import {FORM_ID_ROUTE_PARAM, STRUCTURE_ID_ROUTE_PARAM} from '../../app/route-parameters';

@Component({
  selector: 'app-page-formcreate',
  templateUrl: 'formcreate.html',
  styleUrls: ['./formcreate.css'],
  providers: [
    FormEditService,
  ]
})
export class FormCreatePageComponent implements OnInit, AfterViewChecked {

  ready = false;
  scrollExecuted = false;
  editingForm = false;
  targetItem: string;
  structure: JsonStructure;
  title: string;
  formNameString: string;
  canEdit: boolean;
  private dataAvailable = {structure: false, form: false};

  constructor(private route: ActivatedRoute,
              private structService: StructureService,
              private data: Data,
              private location: Location,
              private modalService: SuiModalService,
              private formService: FormService,
              private session: Session,
              private formEditService: FormEditService) {
  }

  ngOnInit() {
    this.canEdit = this.session.isAdmin;
    const activatedRoute = this.route.snapshot;

    const structId = activatedRoute.params[STRUCTURE_ID_ROUTE_PARAM];
    this.structService.getById(structId).subscribe(structure => {
      this.structure = structure;
      this.structureLoaded();
    });

    const id = activatedRoute.params[FORM_ID_ROUTE_PARAM];
    if (id) {
      console.debug('Id provided loading form');
      this.title = 'Formularanzeige';
      this.formService.load(id, structId).subscribe(form => {
        this.formEditService.setForm(form);
        this.formLoaded();
      });
    } else {
      console.debug('No id provided creating form');
      this.editingForm = true;
      const templateForm = this.data.storage.templateForm;
      const form = templateForm ? templateForm : JsonForm.create('', [], structId);
      if (!form.dbId) {
        form.dbId = Utilities.getUUID();
      }
      this.formEditService.setForm(form);
      this.title = 'Formular erstellen';
      this.formLoaded();
    }

    this.targetItem = activatedRoute.fragment;
  }

  ngAfterViewChecked() {
    if (this.ready && !this.scrollExecuted) {
      let routeFragmentSubscription: Subscription;

      // Automatic scroll
      routeFragmentSubscription =
        this.route.fragment
          .subscribe(fragment => {
            if (fragment) {
              const element = document.getElementById(fragment);
              if (element) {
                element.scrollIntoView();

                this.scrollExecuted = true;

                // Free resources
                setTimeout(
                  () => {
                    console.log('routeFragmentSubscription unsubscribe');
                    routeFragmentSubscription.unsubscribe();
                  }, 1000);
              }
            }
          });
    }
  }

  getFormName(): Observable<string> {
    return this.formEditService.getForm().map(aForm => aForm.name);
  }

  getFormItems(): Observable<JsonFormItem[]> {
    return this.formEditService.getForm().map(aForm => aForm.formItems);
  }

  onDeleteItem(aFormItem: JsonFormItem) {
    console.log('delete : ' + aFormItem.name + ' with id: ' + aFormItem.id);
    this.formEditService.getForm().subscribe(aForm => {
      const arrayToDeleteFrom: JsonFormItem[] = aForm.formItems;
      const index: number = arrayToDeleteFrom.findIndex(curFormItem => curFormItem.id === aFormItem.id);

      if (index > -1) {
        console.log('delete index: ' + index);
        arrayToDeleteFrom.splice(index, 1);
      } else {
        throw new Error(`Could not delete form item with id ${aFormItem.id} from form`);
      }
    });
  }

  onMoveUpItem(aFormItem: JsonFormItem) {
    this.formEditService.getForm().subscribe(aForm => {
      const arrayToSwap: JsonFormItem[] = aForm.formItems;

      const index: number = arrayToSwap.findIndex(i => i.id === aFormItem.id);
      if (index > 0 && index < arrayToSwap.length) { // may not be the first index
        Utilities.swap(arrayToSwap, index, index - 1);
      }
    });
  }

  onMoveDownItem(aFormItem: JsonFormItem) {
    this.formEditService.getForm().subscribe(aForm => {
      const arrayToSwap: JsonFormItem[] = aForm.formItems;

      const index = arrayToSwap.findIndex(i => i.id === aFormItem.id);
      if (index > -1 && index < arrayToSwap.length - 1) { // may not be the last index
        Utilities.swap(arrayToSwap, index, index + 1);
      }
    });
  }

  addSection(): void {
    this.formEditService.getForm().subscribe(aForm => {

      const newSection: JsonFormSection = JsonFormSection.create('', [], this.formEditService.generateTaskId());
      aForm.formItems.push(newSection);
    });
  }

  submit() {
    this.formEditService.getForm().map(aForm => {
      aForm.name = this.formNameString;
      Utilities.ensureDbChannel(aForm, this.structure.operatorId);
      return aForm;
    }).flatMap(aForm => this.formService.put(aForm)).subscribe({
      complete: () => {
        console.info('Successfully saved form');
        this.location.back();
      },
      error: aError => {
        console.error(aError);
        this.modalService.open(new AlertModal('Fehler', 'Formular konnte auf Grund eines Fehlers nicht gespeichert werden'));
      }
    });
  }

  enableEditing() {
    this.editingForm = true;
  }

  private structureLoaded(): void {
    this.dataAvailable.structure = true;
    this.dataLoaded();
  }

  private formLoaded(): void {
    this.formEditService.getForm().subscribe({next: aForm => this.formNameString = aForm.name});
    this.dataAvailable.form = true;
    this.dataLoaded();
  }

  private dataLoaded(): void {
    if (this.dataAvailable.form && this.dataAvailable.structure) {
      this.ready = true;
    }
  }
}
