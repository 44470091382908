import {Injectable} from '@angular/core';
import {RestProvider} from '../providers/rest/rest.provider';
import {JsonFileData, JsonOperator, JsonStructure, Utilities} from 'rueb-data-model';
import {Observable} from 'rxjs';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

export class FileData {
  id: string;
  data: SafeUrl;
  type: string;


  constructor(aId: string, aData: SafeUrl, aType: string) {
    this.id = aId;
    this.data = aData;
    this.type = aType;
  }
}

@Injectable()
export class FileDataService {

  constructor(private restProvider: RestProvider, private sanitizer: DomSanitizer) {
  }

  get(aFileDataId: string): Observable<FileData> {
    return this.restProvider.getRemoteData({id: aFileDataId, appendGet: false, url: `${RestProvider.IMAGE_URL}/`}).map(data => {
      return this.fileDataFromDataUrl(data, aFileDataId);
    });
  }

  getScaledImage(aFileDataId: string, {aWidth, aHeight}: { aWidth?: number, aHeight?: number }): Observable<FileData> {
    return this.restProvider.getRemoteData(
      {id: aFileDataId, appendGet: false, url: `${RestProvider.IMAGE_URL}/`, params: {width: aWidth, height: aHeight}})
      .map(data => {
        return this.fileDataFromDataUrl(data, aFileDataId);
      });
  }

  saveStructureInfo(aFileData: JsonFileData, aStructure: JsonStructure): Observable<JsonFileData> {
    aFileData.operatorId = aStructure.operatorId;
    const jsonData = Utilities.toJson(aFileData, JsonFileData);
    return this.restProvider.putRemoteData(jsonData, `${RestProvider.STRUCT_URL}/${aStructure.dbId}/infos`).map(response => {
      return Utilities.fromJson(response, JsonFileData);
    });
  }

  saveOperatorInfo(aFileData: JsonFileData, aOperator: JsonOperator): Observable<JsonFileData> {
    return this.saveOperatorData(aFileData, aOperator, 'infos');
  }

  saveOperatorLogo(aFileData: JsonFileData, aOperator: JsonOperator): Observable<JsonFileData> {
    return this.saveOperatorData(aFileData, aOperator, 'logo');
  }

  saveOperatorSystemPlan(aFileData: JsonFileData, aOperator: JsonOperator): Observable<JsonFileData> {
    return this.saveOperatorData(aFileData, aOperator, 'systemplan');
  }

  private saveOperatorData(aFileData: JsonFileData, aOperator: JsonOperator, aUrl: string): Observable<JsonFileData> {
    aFileData.operatorId = aOperator.dbId;
    const jsonData = Utilities.toJson(aFileData, JsonFileData);
    return this.restProvider.putRemoteData(jsonData, `${RestProvider.OPERATORS_URL}/${aOperator.dbId}/${aUrl}`).map(response => {
      return Utilities.fromJson(response, JsonFileData);
    });
  }

  private fileDataFromDataUrl(aDataUrl, aFileDataId: string) {
    const dataUrl = aDataUrl as string;
    const mimeTypeRegex: RegExp = /^data:([a-z]+\/([a-z]+));base64,/;
    const matches = mimeTypeRegex.exec(dataUrl);
    const mimeType: string = matches[1];
    const safeUrl = this.sanitizer.bypassSecurityTrustUrl(dataUrl);
    return new FileData(aFileDataId, safeUrl, mimeType);
  }
}
