import {Component} from '@angular/core';
import {ComponentModalConfig, SuiModal} from 'ng2-semantic-ui';
import * as FileSaver from 'file-saver';
import {Utilities} from 'rueb-data-model';
import {FileData} from '../../services/file-data.service';

@Component({
  selector: 'app-picture-modal',
  templateUrl: 'picture-modal.component.html',
  styleUrls: ['picture-modal.css']
})
export class PictureModalComponent {
  picture: FileData;
  fileInfo: FileInfo;

  constructor(private modal: SuiModal<IPictureModalContext, void, void>) {
    this.picture = this.modal.context.picture;
    this.fileInfo = this.modal.context.fileInfo;
  }

  downloadFile() {
    if (this.fileInfo) {
      const mimeTypeRegex: RegExp = /^[a-z]+\/([a-z]+)$/;
      const matches = mimeTypeRegex.exec(this.picture.type);
      const imageType: string = matches[1];

      const dataArray: Uint8Array = Utilities.convertDataURIToBinary(this.picture.data['changingThisBreaksApplicationSecurity']);
      const blob = new Blob([dataArray], {type: this.picture.type});
      FileSaver.saveAs(blob, `${this.fileInfo.fileName}.${imageType}`);
    }
  }
}

export interface FileInfo {
  fileName: string;
}

export interface IPictureModalContext {
  picture: FileData;
  fileInfo?: FileInfo;
}

export class PictureModal extends ComponentModalConfig<IPictureModalContext, void, void> {
  constructor(aPicture: FileData, aFileInfo: FileInfo) {
    super(PictureModalComponent, {picture: aPicture, fileInfo: aFileInfo});
    this.isClosable = true;
    this.transitionDuration = 200;
  }
}
