import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

export interface DisplayInfoData {
  dataId: string;
  filename: string;
}

@Injectable()
export class DisplayStructureInfoService implements OnDestroy {
  private _curIndex: BehaviorSubject<number> = new BehaviorSubject(-1);

  constructor() {

  }

  private _infos: DisplayInfoData[] = [];

  set infos(aInfos: DisplayInfoData[]) {
    this._infos = [].concat(aInfos);
    this._curIndex.next(0);
  }

  private _curInfo: Observable<DisplayInfoData>;

  get curInfo(): Observable<DisplayInfoData> {
    if (this._curInfo == null) {
      this._curInfo = this._curIndex.map(aIndex => this._infos[aIndex]);
    }
    return this._curInfo;
  }

  ngOnDestroy(): void {
    console.debug('Destroying structure info service');
    this._curIndex.complete();
  }

  next(): void {
    let nextIndex = this._curIndex.getValue() + 1;
    if (nextIndex >= this._infos.length) {
      nextIndex = 0;
    }
    this._curIndex.next(nextIndex);
  }

  previous(): void {
    let nextIndex = this._curIndex.getValue() - 1;
    if (nextIndex < 0) {
      nextIndex = this._infos.length - 1;
    }
    this._curIndex.next(nextIndex);
  }

  updateCurInfo(aInfo: DisplayInfoData): void {
    let nextIndex = this._infos.findIndex(aSearchInfo => aSearchInfo.dataId === aInfo.dataId && aSearchInfo.filename === aInfo.filename);
    if (nextIndex < 0) {
      this._infos.push(aInfo);
      nextIndex = this._infos.length - 1;
    }
    this._curIndex.next(nextIndex);
  }
}
