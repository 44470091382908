import {Injectable} from '@angular/core';
import {JsonPage, JsonStructure, JsonTimetrackingTask, JsonUser, Utilities} from 'rueb-data-model';
import {Observable} from 'rxjs';
import {PageRequest} from '../../../utilities/page-request';
import {DataPage} from '../../../utilities/data-page';
import {PaginationUtil} from '../../../utilities/pagination/pagination-util';
import {RestProvider} from '../../../providers/rest/rest.provider';
import {DateTime} from 'luxon';

@Injectable()
export class TimetrackingService {
  constructor(private restService: RestProvider) {

  }

  loadTimetrackingTasks(aPageRequest: PageRequest,
                        fromFilter?: DateTime,
                        toFilter?: DateTime,
                        usersFilter?: JsonUser[],
                        structuresFilter?: JsonStructure[],
                        descending?: boolean): Observable<DataPage<JsonTimetrackingTask>> {
    const params = {
      size: aPageRequest.size,
      page: aPageRequest.page,
    };
    this.createParamsFromFilters(params, fromFilter, toFilter, usersFilter, structuresFilter);
    if (descending) {
      params['descending'] = descending + '';
    }
    console.log('load params', params);
    return this.restService.getRemoteData({
      id: undefined,
      appendGet: false,
      url: `${RestProvider.TIMETRACKING_URL}/tasks`,
      params: params
    })
      .map(aJson => Utilities.fromJson(aJson, JsonPage))
      .map(aJsonPage => PaginationUtil.createDataPageFrom(aJsonPage, JsonTimetrackingTask));
  }

  loadTimetrackingTask(aTimetrackingTaskId: string): Observable<JsonTimetrackingTask> {
    return this.restService.getRemoteData({id: aTimetrackingTaskId, appendGet: false, url: `${RestProvider.TIMETRACKING_URL}/tasks/`})
      .map(aJson => Utilities.fromJson(aJson, JsonTimetrackingTask));
  }

  saveTimetrackingTask(aTimetrackingTask: JsonTimetrackingTask): Observable<JsonTimetrackingTask> {
    if (aTimetrackingTask.id == null) {
      aTimetrackingTask.id = Utilities.getUUID();
    }
    const jsonData = Utilities.toJson(aTimetrackingTask, JsonTimetrackingTask);
    return this.restService.putRemoteData(jsonData, `${RestProvider.TIMETRACKING_URL}/tasks/${aTimetrackingTask.id}`)
      .map(aJson => Utilities.fromJson(aJson, JsonTimetrackingTask));
  }

  deleteTimetrackingTask(aTimetrackingTaskId: string): Observable<JsonTimetrackingTask> {
    return this.restService.deleteRemoteDataRest(`${RestProvider.TIMETRACKING_URL}/tasks/${aTimetrackingTaskId}`)
      .map(aJson => Utilities.fromJson(aJson, JsonTimetrackingTask));
  }

  exportTimetrackingTasks(fromFilter?: DateTime, toFilter?: DateTime, usersFilter?: JsonUser[],
                          structuresFilter?: JsonStructure[]): Observable<Blob> {
    const params = {};
    this.createParamsFromFilters(params, fromFilter, toFilter, usersFilter, structuresFilter);
    return this.restService.postRemoteBlob({}, `${RestProvider.TIMETRACKING_URL}/tasks/export`, params);
  }

  private createParamsFromFilters(params, fromFilter: DateTime, toFilter: DateTime, usersFilter: JsonUser[],
                                  structuresFilter: JsonStructure[]) {
    if (fromFilter) {
      params['from'] = fromFilter.toISODate();
    }
    if (toFilter) {
      params['to'] = toFilter.toISODate();
    }
    if (usersFilter && usersFilter.length > 0) {
      params['users'] = usersFilter.map(aUser => aUser.dbId);
    }
    if (structuresFilter && structuresFilter.length > 0) {
      params['structures'] = structuresFilter.map(aStructure => aStructure.dbId);
    }
  }
}
