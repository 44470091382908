import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavigationBarComponent} from './navigation-bar/navigation-bar.component';
import {NavigationService} from './navigation.service';
import {BreadcrumbModule} from 'primeng/primeng';
import {NavOperatorListGuard} from './navigation-guards/nav-operator-list.guard';
import {NavOperatorCreateGuard} from './navigation-guards/nav-operator-create.guard';
import {NavOperatorDetailGuard} from './navigation-guards/nav-operator-detail.guard';
import {NavOperatorInfoViewGuard} from './navigation-guards/nav-operator-info-view.guard';
import {NavOperatorStructureCreateGuard} from './navigation-guards/nav-operator-structure-create.guard';
import {NavOperatorStructureDetailGuard} from './navigation-guards/nav-operator-structure-detail.guard';
import {NavOperatorStructureFormsCreateGuard} from './navigation-guards/nav-operator-structure-forms-create.guard';
import {NavOperatorStructureFormsDetailGuard} from './navigation-guards/nav-operator-structure-forms-detail.guard';
import {NavOperatorStructureProtocolsDetailGuard} from './navigation-guards/nav-operator-structure-protocols-detail.guard';
import {NavOperatorStructureInfosViewGuard} from './navigation-guards/nav-operator-structure-infos-view.guard';
import {NavStructuresListGuard} from './navigation-guards/nav-structures-list.guard';
import {NavStructuresStructureDetailGuard} from './navigation-guards/nav-structures-structure-detail.guard';
import {NavStructuresStructureFormsDetailGuard} from './navigation-guards/nav-structures-structure-forms-detail.guard';
import {NavStructuresStructureProtocolsDetailGuard} from './navigation-guards/nav-structures-structure-protocols-detail.guard';
import {NavStructuresStructureInfosViewGuard} from './navigation-guards/nav-structures-structure-infos-view.guard';
import {NavUsersListGuard} from './navigation-guards/nav-users-list.guard';
import {NavUsersCreateGuard} from './navigation-guards/nav-users-create.guard';
import {NavUsersDetailGuard} from './navigation-guards/nav-users-detail.guard';
import {NavClearGuard} from './navigation-guards/nav-clear.guard';
import {NavTimetrackingOverviewGuard} from "./navigation-guards/nav-timetracking-overview.guard";

const parts = [
  NavigationBarComponent
];

const navGuards = [
  NavOperatorListGuard,
  NavOperatorCreateGuard,
  NavOperatorDetailGuard,
  NavOperatorInfoViewGuard,
  NavOperatorStructureCreateGuard,
  NavOperatorStructureDetailGuard,
  NavOperatorStructureFormsCreateGuard,
  NavOperatorStructureFormsDetailGuard,
  NavOperatorStructureProtocolsDetailGuard,
  NavOperatorStructureInfosViewGuard,
  NavStructuresListGuard,
  NavStructuresStructureDetailGuard,
  NavStructuresStructureFormsDetailGuard,
  NavStructuresStructureProtocolsDetailGuard,
  NavStructuresStructureInfosViewGuard,
  NavUsersListGuard,
  NavUsersCreateGuard,
  NavUsersDetailGuard,
  NavTimetrackingOverviewGuard,
  NavClearGuard,
];

@NgModule({
  declarations: parts,
  imports: [
    CommonModule,
    BreadcrumbModule,
  ],
  exports: parts,
  providers: [
    NavigationService,
    ...navGuards,
  ]
})
export class NavigationModule {

}
