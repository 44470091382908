import {Injectable} from '@angular/core';
import {RestProvider} from '../providers/rest/rest.provider';
import {JsonProtocol, Utilities} from 'rueb-data-model';
import {Observable} from 'rxjs';

@Injectable()
export class ProtocolService {

  constructor(private restProvider: RestProvider) {
  }

  load(protocolId: string, aStructureId: string): Observable<JsonProtocol> {
    return this.restProvider.getRemoteData({appendGet: false, url: `${RestProvider.STRUCT_URL}/${aStructureId}/protocols/${protocolId}`})
      .map(value => {
        return Utilities.fromJson(value, JsonProtocol);
      });
  }
}
