import {Component, forwardRef, Input} from '@angular/core';
import {ValueAccessorBase} from '../ValueAccessorBase';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {DateTime} from 'luxon';

/**
 * Generated class for the FormHeadingComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'form-heading-semantic',
  templateUrl: 'form-heading-semantic.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormHeadingSemanticComponent),
    multi: true,
  }],
})
export class FormHeadingSemanticComponent extends ValueAccessorBase<string> {
  @Input() itemLabel: string;
  @Input() enReadonly: boolean;
  @Input() dueDate: DateTime;

  constructor() {
    super();
    this.value = '';
  }

  keyHandler(aEvent: KeyboardEvent) {
    if (aEvent.keyCode === 13) {
      this.labelChanged.emit(this.itemLabel);
      aEvent.preventDefault();
    }
  }
}
