import {Component} from '@angular/core';
import {Session} from '../../providers/session/session';
import {Router} from '@angular/router';

@Component({
  selector: 'app-betriebstagebuch-header',
  templateUrl: 'betriebstagebuch-header.component.html',
  styleUrls: ['betriebstagebuch-header.component.css']
})
export class BetriebstagebuchHeaderComponent {

  constructor(public session: Session, private router: Router) {
  }

  closeBetriebstagebuch() {
    this.session.isBetriebstagebuchOpen = false;
    this.router.navigate(['']).catch(err => console.error('Could not open entry page', err));
  }
}
