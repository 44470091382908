import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SuiModule} from 'ng2-semantic-ui';
import {StructureListComponent} from './structurelist';
import {OperatorStructureListComponent} from './operator-structure-list/operator-structure-list.component';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [
    StructureListComponent,
    OperatorStructureListComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SuiModule,
  ],
  entryComponents: [
    StructureListComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class StructureListModule {

}
