import {SuiModal} from 'ng2-semantic-ui';
import {Component} from '@angular/core';

export interface IAlertModalContext {
  title: string;
  message: string;
}

@Component({
  selector: 'app-modal-alert',
  templateUrl: 'alert-modal.component.html'
})
export class AlertModalComponent {
  constructor(public modal: SuiModal<IAlertModalContext, void, void>) {
  }
}
