import {ControlValueAccessor} from "@angular/forms";
import {EventEmitter, Input, Output} from '@angular/core';

export class ValueAccessorBase<T> implements ControlValueAccessor {
  @Input() edit: boolean;
  @Output() labelChanged = new EventEmitter<string>();

  private innerValue: T;

  private isDisabled: boolean;


  private changedListeners: Array<(value: T) => void> = [];
  private touchedListeners: Array<() => void> = [];


  get value(): T {
    return this.innerValue;
  }


  set value(value: T) {
    if (this.innerValue !== value) {
      this.innerValue = value;
      this.changedListeners.forEach(f => f(value));
    }
  }


  touch() {
    this.edit = false;
    this.touchedListeners.forEach(f => f());
  }


  writeValue(value: T) {
    this.innerValue = value;
  }


  registerOnChange(fn: (value: T) => void) {
    this.changedListeners.push(fn);
  }


  registerOnTouched(fn: () => void) {
    this.touchedListeners.push(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

}
