import {JsonForm, JsonOperator, JsonStructure} from 'rueb-data-model';
import {Component} from '@angular/core';
import {ComponentModalConfig, ModalSize, SuiModal} from 'ng2-semantic-ui';


export class FormSelectionOptionVM {
  form: JsonForm;
}

export class StructureSelectOptionVM {
  structure: JsonStructure;
  forms: FormSelectionOptionVM[];
}

export class OperatorSelectOptionVM {
  operator: JsonOperator;
  structures: StructureSelectOptionVM[];
}

@Component({
  selector: 'app-edit-task-modal',
  templateUrl: 'select-form-modal.html',
  styleUrls: ['select-form-modal.css'],
})
export class SelectFormModalComponent {

  ready = false;
  selectOptions: OperatorSelectOptionVM[];

  constructor(public modal: SuiModal<ISelectFormModalContext, SelectFormModalResult, void>) {
    this.selectOptions = modal.context.selectOptions;
  }

  formSelected(aForm: JsonForm) {
    this.modal.approve({selectedForm: aForm});
  }
}

export interface ISelectFormModalContext {
  selectOptions: OperatorSelectOptionVM[];
}

export class SelectFormModal extends ComponentModalConfig<ISelectFormModalContext, SelectFormModalResult, void> {
  constructor(selectOptions: OperatorSelectOptionVM[]) {
    super(SelectFormModalComponent, {selectOptions: selectOptions});
    this.isClosable = true;
    this.transitionDuration = 200;
    this.size = ModalSize.Tiny;
  }
}

export interface SelectFormModalResult {
  selectedForm: JsonForm;
}
