import {JsonPage} from 'rueb-data-model';
import {DataPage} from '../data-page';
import {TypedJSON} from 'typedjson';
import {Constructor} from 'typedjson/js/typedjson/types';

export class PaginationUtil {
  public static createDataPageFrom<T>(aJsonPage: JsonPage, aClassType: Constructor<T>): DataPage<T> {
    const dataPage = new DataPage<T>();
    dataPage.content = TypedJSON.parseAsArray(aJsonPage.content, aClassType);
    dataPage.empty = aJsonPage.empty;
    dataPage.first = aJsonPage.first;
    dataPage.last = aJsonPage.last;
    dataPage.number = aJsonPage.number;
    dataPage.numberOfElements = aJsonPage.numberOfElements;
    dataPage.pageable = aJsonPage.pageable;
    dataPage.size = aJsonPage.size;
    dataPage.sort = aJsonPage.sort;
    dataPage.totalElements = aJsonPage.totalElements;
    dataPage.totalPages = aJsonPage.totalPages;

    return dataPage;
  }
}
