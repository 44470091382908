import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RestProvider} from '../../providers/rest/rest.provider';
import {Session} from '../../providers/session/session';
import {OperatorService} from '../../services/operator-service';

@Component({
  selector: 'app-login',
  templateUrl: 'login.html',
  styleUrls: ['login.css']
})
export class LoginComponent implements OnInit {
  login: string;
  password: string;

  ready = false;

  constructor(private session: Session,
              private route: ActivatedRoute,
              private router: Router,
              private restProvider: RestProvider,
              private operatorService: OperatorService) {
  }

  ngOnInit(): void {
    console.log('Initializing LoginComponent');
    const returnUrl = this.route.snapshot.queryParams.returnUrl;
    this.restProvider.getRoleHint().subscribe(roleHint => {
      this.session.isLoggedIn = true;
      this.session.isUser = true;
      this.session.isAdmin = (roleHint['role_hint'].toLowerCase() === 'admin');
      this.session.isBetriebstagebuchAvailable = roleHint['betriebstagebuchAvailable'];
      this.session.isTimetrackingAvailable = roleHint['timetrackingAvailable'];
      this.session.isBetriebstagebuchOpen = this.session.isAdmin;
    }, () => {
      localStorage.removeItem('id_token');
      this.loggedInCheckCompleted(returnUrl);
    }, () => {
      this.loggedInCheckCompleted(returnUrl);
    });
  }

  submitForm(data: {}) {
    this.session.isLoggedIn = false;
    this.session.isAdmin = false;
    this.session.isUser = false;
    this.restProvider.login(data).subscribe(n => {
      this.session.isLoggedIn = true;
      this.session.isUser = true;
      this.session.isAdmin = (n['role_hint'].toLowerCase() === 'admin');
      this.session.isBetriebstagebuchAvailable = n['betriebstagebuchAvailable'];
      this.session.isTimetrackingAvailable = n['timetrackingAvailable'];
      localStorage.setItem('id_token', n['token']);
      if (this.session.isAdmin) {
        this.session.isBetriebstagebuchOpen = true;
        this.router.navigate(['/operators']);
      } else {
        this.router.navigate(['']);
        this.operatorService.getOwn().subscribe(aOperator => {
          if (aOperator.betriebstagebuchCustomName) {
            this.session.betriebstagebuchName = aOperator.betriebstagebuchCustomName;
          }
        });
      }
    });
  }

  private loggedInCheckCompleted(returnUrl) {
    this.ready = true;
    if (this.session.isLoggedIn) {
      this.router.navigateByUrl(returnUrl);
    }
  }
}
