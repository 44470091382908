import {DateTime} from 'luxon';
import {JsonStructure, JsonUser} from 'rueb-data-model';

export class TasksRequest {
  requestedPage: number;
  pageSize: number;
  fromFilter: DateTime;
  endFilter: DateTime;
  usersFilter: JsonUser[];
  structuresFilter: JsonStructure[];
  descending: boolean;


  constructor(requestedPage: number, pageSize: number, fromFilter?: DateTime, endFilter?: DateTime, usersFilter?: JsonUser[],
              structuresFilter?: JsonStructure[], descending?: boolean) {
    this.requestedPage = requestedPage;
    this.pageSize = pageSize;
    this.fromFilter = fromFilter;
    this.endFilter = endFilter;
    this.usersFilter = usersFilter;
    this.structuresFilter = structuresFilter;
    this.descending = descending;
  }
}
