import {Injectable} from '@angular/core';
import {NavigationService} from '../navigation.service';
import {OperatorService} from '../../services/operator-service';
import {MenuItem} from 'primeng/api';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {INFO_ID_ROUTE_PARAM, OPERATOR_ID_ROUTE_PARAM} from '../../app/route-parameters';
import {Session} from '../../providers/session/session';

@Injectable()
export class NavOperatorInfoViewGuard implements Resolve<MenuItem[]> {

  constructor(private navigationService: NavigationService,
              private operatorService: OperatorService,
              private session: Session) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MenuItem[]> | Promise<MenuItem[]> | MenuItem[] {
    const operatorId = route.paramMap.get(OPERATOR_ID_ROUTE_PARAM);
    const infoId = route.paramMap.get(INFO_ID_ROUTE_PARAM);

    if (this.session.isBetriebstagebuchOpen) {
      return this.operatorService.load(operatorId).map(aOperator => {
        const items: MenuItem[] = [
          {label: 'Betreiber', routerLink: ['/operators']},
          {label: aOperator.name, routerLink: ['/operators', aOperator.dbId]},
          {label: 'Aktuelle Info', routerLink: ['/operators', aOperator.dbId, 'infos', infoId]}
        ];
        return items;
      }).do(aItems => {
        this.navigationService.clearItems();
        this.navigationService.addItems(...aItems);
      });
    } else {
      const items = [{label: 'Startseite', routerLink: ['']}];
      this.navigationService.clearItems();
      this.navigationService.addItems(...items);
      return items;
    }
  }
}
