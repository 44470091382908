import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ValueAccessorBase} from '../ValueAccessorBase';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {DateTime} from 'luxon';
import {RuebInterval} from 'rueb-data-model';

/**
 * Generated class for the FormTextAreaComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'form-text-area-semantic',
  templateUrl: 'form-text-area-semantic.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormTextAreaSemanticComponent),
    multi: true,
  }],
})
export class FormTextAreaSemanticComponent extends ValueAccessorBase<string> implements OnInit {

  @Input() itemLabel: string;
  @Input() enReadonly: boolean;
  @Input() dueDate: DateTime;
  @Input() taskInterval: RuebInterval;
  @Input() textValue: string;


  constructor() {
    super();
  }

  ngOnInit(): void {
    this.writeValue(this.textValue);
  }

  changed(aEvent: KeyboardEvent) {
    if (aEvent.keyCode === 13) {
      this.labelChanged.emit(this.itemLabel);
      aEvent.preventDefault();
    }
  }
}
