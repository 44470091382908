import {Component} from '@angular/core';
import {NavigationService} from '../navigation.service';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: 'navigation-bar.component.html',
  styleUrls: ['navigation-bar.component.css']
})
export class NavigationBarComponent {

  constructor(private navigationService: NavigationService) {
  }
}
