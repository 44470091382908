import {Injectable} from '@angular/core';
import {NavigationService} from '../navigation.service';
import {MenuItem} from 'primeng/api';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {FORM_ID_ROUTE_PARAM, STRUCTURE_ID_ROUTE_PARAM} from '../../app/route-parameters';
import {StructureService} from '../../services/strcuture-service';
import {FormService} from '../../services/form.service';

@Injectable()
export class NavStructuresStructureFormsDetailGuard implements Resolve<MenuItem[]> {

  constructor(private navigationService: NavigationService,
              private structureService: StructureService,
              private formService: FormService) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MenuItem[]> | Promise<MenuItem[]> | MenuItem[] {
    const structureId = route.paramMap.get(STRUCTURE_ID_ROUTE_PARAM);
    const formId = route.paramMap.get(FORM_ID_ROUTE_PARAM);

    const structure$ = this.structureService.getById(structureId);
    const form$ = this.formService.load(formId, structureId);

    return Observable.zip(structure$, form$, (aStructure, aForm) => {
      const items: MenuItem[] = [
        {label: 'Bauwerke', routerLink: ['/structures']},
        {label: aStructure.name, routerLink: ['/structures', aStructure.dbId]},
        {label: aForm.name, routerLink: ['/structures', aStructure.dbId, 'forms', aForm.dbId]},
      ];
      return items;
    }).do(aItems => {
      this.navigationService.clearItems();
      this.navigationService.addItems(...aItems);
    });
  }
}
