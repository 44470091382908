import {Injectable} from '@angular/core';
import {RestProvider} from '../providers/rest/rest.provider';
import {JsonForm, JsonProtocol, JsonStructure, Utilities} from 'rueb-data-model';
import {Observable} from 'rxjs';
import {FormService} from './form.service';
import {TypedJSON} from 'typedjson';
import {JsonFormRequest} from 'rueb-data-model/src/json-form-request';

const PROTOCOLS_STRUCTURE_URL = 'protocols';
const FORMS_STRUCTURE_URL = 'forms';

@Injectable()
export class StructureService {
  constructor(private rest: RestProvider, private formService: FormService) {
  }

  getById(structureId: string): Observable<JsonStructure> {
    return this.rest.getRemoteData({appendGet: false, url: `${RestProvider.STRUCT_URL}/${structureId}`})
      .map(jsonStructure => Utilities.fromJson(jsonStructure, JsonStructure));
  }

  put(structure: JsonStructure): Observable<JsonStructure> {
    Utilities.ensureDbChannel(structure, structure.operatorId);
    const jsonStructure = Utilities.toJson(structure, JsonStructure);
    console.log(jsonStructure);
    return this.rest.putRemoteData(jsonStructure, RestProvider.STRUCT_URL)
      .map(savedStructure => Utilities.fromJson(savedStructure, JsonStructure));
  }

  delete(structure: JsonStructure): Observable<any> {
    return this.rest.deleteRemoteDataRest(`${RestProvider.STRUCT_URL}/${structure.dbId}`);
  }

  getProtocols(aStructureId: string): Observable<Array<JsonProtocol>> {
    return this.rest.query(`${RestProvider.STRUCT_URL}/${aStructureId}/${PROTOCOLS_STRUCTURE_URL}`).map(value => {
      const result: Array<JsonProtocol> = [];
      for (const i of value as string[]) {
        result.push(Utilities.fromJson(i, JsonProtocol));
      }
      return result;
    });
  }

  getForms(aStructureId: string): Observable<JsonForm[]> {
    return this.rest.query(`${RestProvider.STRUCT_URL}/${aStructureId}/${FORMS_STRUCTURE_URL}`).map(value => {
      const result: Array<JsonForm> = [];
      for (const i of value as string[]) {
        result.push(Utilities.fromJson(i, JsonForm));
      }
      return result;
    });
  }

  getFormsGrouped(aStructureIds: string[], aIncludeDueDates: boolean): Observable<Map<String, JsonForm[]>> {
    const jsonFormRequest = new JsonFormRequest();
    jsonFormRequest.structureIds = aStructureIds;
    jsonFormRequest.includeDueDates = aIncludeDueDates;
    return this.rest.query(`${RestProvider.STRUCT_URL}/${FORMS_STRUCTURE_URL}`, jsonFormRequest).map(value => {
      const jsonFormsMap: {} = value;
      const formsMap: Map<string, JsonForm[]> = new Map<string, JsonForm[]>();
      for (const curKey in jsonFormsMap) {
        const forms: JsonForm[] = TypedJSON.parseAsArray(jsonFormsMap[curKey], JsonForm);
        formsMap.set(curKey, forms);
      }
      return formsMap;
    });
  }

  getProtocolsGrouped(aStructureIds: string[]): Observable<Map<String, JsonProtocol[]>> {
    return this.rest.query(`${RestProvider.STRUCT_URL}/${PROTOCOLS_STRUCTURE_URL}`, aStructureIds).map(value => {
      const jsonProtocolsMap: {} = value;
      const protocolsMap: Map<string, JsonProtocol[]> = new Map<string, JsonProtocol[]>();
      for (const curKey in jsonProtocolsMap) {
        const protocols: JsonProtocol[] = TypedJSON.parseAsArray(jsonProtocolsMap[curKey], JsonProtocol);
        protocolsMap.set(curKey, protocols);
      }
      return protocolsMap;
    });
  }

  getAll(): Observable<JsonStructure[]> {
    return this.rest.getAllRemoteData(RestProvider.STRUCT_URL).map(value => {
      return TypedJSON.parseAsArray(value, JsonStructure);
    });
  }
}
