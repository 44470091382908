import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  JsonFileData,
  JsonProtocol,
  JsonStructureInfo,
  JsonStructureInfoDocumentSection,
  PlaceholderSections,
  Utilities
} from 'rueb-data-model';
import {ProtocolPictureVM} from '../protocol-pictures-placeholder/protocol-pictures-placeholder.component';
import {SuiModalService} from 'ng2-semantic-ui';
import {AlertModal} from '../../modals/confirm-modal';
import {DisplayStructureInfoService} from '../../pages/display-structure-info/service/display-structure-info.service';
import {ActivatedRoute, Router} from '@angular/router';

export class StructInfoDocSectionVM {
  section: JsonStructureInfoDocumentSection;
  subSections: StructInfoDocSectionVM[];
  placeholderData: any;

  constructor(section: JsonStructureInfoDocumentSection, placeholderData?: any) {
    this.section = section;
    this.placeholderData = placeholderData;
  }

  public static convertSectionsToVM(aSections: JsonStructureInfoDocumentSection[], aProtocols: JsonProtocol[]): StructInfoDocSectionVM[] {
    const vms: StructInfoDocSectionVM[] = [];
    for (const curSection of aSections) {
      curSection.infos.sort((a, b) => a.name.localeCompare(b.name));
      const curVM: StructInfoDocSectionVM = new StructInfoDocSectionVM(curSection);
      vms.push(curVM);
      if (curSection.subSections) {
        curVM.subSections = StructInfoDocSectionVM.convertSectionsToVM(curSection.subSections, aProtocols);
      } else {
        curVM.subSections = [];
      }
      switch (curSection.placeholderFor) {
        case PlaceholderSections.PROTOCOL_PICTURES:
          const protocolPicturesList = this.createProtocolPicturesPlaceholderData(aProtocols);

          curVM.placeholderData = protocolPicturesList;
          break;
        default:
          break;
      }
    }
    return vms.sort((a, b) => a.section.label.localeCompare(b.section.label.toString()));
  }

  public static createProtocolPicturesPlaceholderData(aProtocols: JsonProtocol[]): ProtocolPictureVM[] {
    const protocolPicturesList: ProtocolPictureVM[] = [];
    for (const curProtocol of aProtocols) {
      for (const curProtocolItem of curProtocol.values) {
        const curProtocolNote = curProtocolItem.note;
        if (curProtocolNote && curProtocolNote.pictures) {
          for (const curPicture of curProtocolNote.pictures) {
            const curPictureVM: ProtocolPictureVM = new ProtocolPictureVM();
            curPictureVM.picture = curPicture;
            curPictureVM.pictureLabel = curPicture.date ? curPicture.date.toFormat('ff') : curProtocol.createdDate.toFormat('DD');
            protocolPicturesList.push(curPictureVM);
          }
        }
      }
    }
    return protocolPicturesList;
  }
}

@Component({
  selector: 'app-struct-info-doc-section',
  templateUrl: './struct-info-doc-section.component.html',
  styleUrls: ['./struct-info-doc-section.component.css']
})
export class StructInfoDocSectionComponent implements OnInit {
  @Input()
  structInfoSection: StructInfoDocSectionVM;
  @Input()
  canEdit: boolean;

  @Output()
  removeSectionRequest = new EventEmitter<JsonStructureInfoDocumentSection>();
  @Output()
  subSectionAdded = new EventEmitter<{ section: JsonStructureInfoDocumentSection, subSection: JsonStructureInfoDocumentSection }>();
  @Output()
  subSectionRemoved = new EventEmitter<{ section: JsonStructureInfoDocumentSection, subSection: JsonStructureInfoDocumentSection }>();
  @Output()
  infoAdded = new EventEmitter<{ section: JsonStructureInfoDocumentSection, info: JsonStructureInfo, fileData: JsonFileData }>();
  @Output()
  infoRemoved = new EventEmitter<{ section: JsonStructureInfoDocumentSection, info: JsonStructureInfo }>();

  placeholderSections = PlaceholderSections;

  editingSection = false;

  sectionFormLabel: string;

  constructor(private modalService: SuiModalService,
              private displayInfoService: DisplayStructureInfoService,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
  }

  displayInfo(aInfo: JsonStructureInfo) {
    this.displayInfoService.infos = this.structInfoSection.section.infos.map(aCurInfo => {
      return {dataId: aCurInfo.dataId, filename: aCurInfo.name};
    });
    this.router.navigate(['./infos', aInfo.dataId, {filename: aInfo.name}], {relativeTo: this.activatedRoute});
  }


  addSection() {
    if (this.sectionFormLabel) {
      const addedSection = JsonStructureInfoDocumentSection.create(this.sectionFormLabel);
      this.sectionFormLabel = undefined;
      this.structInfoSection.section.subSections.push(addedSection);
      this.subSectionAdded.emit({section: this.structInfoSection.section, subSection: addedSection});
      this.editingSection = false;
    } else {
      console.error('Cannot create section with empty label');
    }
  }

  removeSection() {
    this.removeSectionRequest.emit(this.structInfoSection.section);
  }

  handleRemoveSectionRequest(aSection: JsonStructureInfoDocumentSection) {
    this.modalService.open(new AlertModal('Löschen', `Möchten Sie den Abschnitt ${aSection.label} wirklich löschen?`)).onApprove(() => {
      Utilities.removeElementFromArray(this.structInfoSection.section.subSections, aSection);
      this.subSectionRemoved.emit({section: this.structInfoSection.section, subSection: aSection});
    });
  }

  addInfo(fileInput: HTMLInputElement) {
    if (fileInput.files != null && fileInput.files.length > 0) {
      const selectedFile: File = fileInput.files.item(0);
      console.log(selectedFile.type);
      if (selectedFile.type === 'application/pdf' || selectedFile.type.startsWith('image')) {
        const fileReader: FileReader = new FileReader();
        fileReader.onload = ev => {
          const result = ev.target['result'];
          if (result) {
            const fileData: JsonFileData = JsonFileData.create(result, selectedFile.type);
            const structureInfo: JsonStructureInfo = JsonStructureInfo.create(selectedFile.name, fileData.dbId);
            this.structInfoSection.section.infos.push(structureInfo);
            this.editingSection = false;
            this.infoAdded.emit({section: this.structInfoSection.section, info: structureInfo, fileData: fileData});
          }
        };
        fileReader.readAsDataURL(selectedFile);
      } else {
        console.error('You must select an image or pdf');
      }
    } else {
      console.error('You must select a file');
    }
  }

  removeInfo(info: JsonStructureInfo) {
    this.modalService.open(new AlertModal('Löschen', `Möchten Sie die Datei ${info.name} wirklich löschen?`)).onApprove(() => {
      Utilities.removeElementFromArray(this.structInfoSection.section.infos, info);
      this.infoRemoved.emit({section: this.structInfoSection.section, info: info});
    });
  }

  toggleAddingSection() {
    this.editingSection = !this.editingSection;
  }
}
