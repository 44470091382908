import 'rxjs/add/observable/of';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/zip';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/takeUntil';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/finally';

import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {RestProvider} from '../providers/rest/rest.provider';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {StructDetailsComponent} from '../pages/structdetails/struct-details.component';
import {SuiModule} from 'ng2-semantic-ui';
import {FormsModule} from '@angular/forms';
import {Data} from '../providers/data/data.provider';
import {AlertModalComponent} from '../modals/alert-modal.component';
import {FormcreatePageModule} from '../pages/formcreate/formcreate.module';
import {OperatorService} from '../services/operator-service';
import {StructureService} from '../services/strcuture-service';
import {AdminComponent} from '../pages/admin/admin.component';
import {UserCreateComponent} from '../pages/admin/user-create.component';
import {UserService} from '../services/user-service';
import {LoginComponent} from '../pages/login/login.component';
import {AuthInterceptor} from '../providers/auth.service';
import {Session} from '../providers/session/session';
import {AdminGuard} from '../providers/guards/admin-guard';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {DisplayStructureInfoPage} from '../pages/display-structure-info/display-structure-info';
import {ShowProtocolPageModule} from '../pages/show-protocol/show-protocol.module';
import {FormService} from '../services/form.service';
import {ProtocolService} from '../services/protocol.service';
import {SelectFormModalComponent} from '../pages/structdetails/select-form-modal';
import {PictureModalComponent} from '../modals/pictureModal/picture-modal.component';
import {EntryPageComponent} from '../pages/entrypage/entry-page.component';
import {AuthGuard} from '../providers/guards/auth-guard';
import {RuebGuard} from '../providers/guards/rueb-guard';
import {FileDataService} from '../services/file-data.service';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {StructureListModule} from '../pages/structurelist/structure-list.module';
import {OperatorsModule} from '../pages/operators/operators.module';
import {CommonComponentsModule} from '../components/common-components.module';
import {DisplayStructureInfoService} from '../pages/display-structure-info/service/display-structure-info.service';
import {NavigationModule} from '../navigation/navigation.module';
import {HeaderModule} from '../header/header.module';
import {FeaturesService} from '../providers/features/features.service';
import {TimetrackingModule} from '../pages/timetracking/timetracking.module';

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AppComponent,
    StructDetailsComponent,
    AlertModalComponent,
    AdminComponent,
    UserCreateComponent,
    LoginComponent,
    DisplayStructureInfoPage,
    SelectFormModalComponent,
    PictureModalComponent,
    EntryPageComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    SuiModule,
    FormsModule,
    FormcreatePageModule,
    PdfViewerModule,
    ShowProtocolPageModule,
    StructureListModule,
    OperatorsModule,
    CommonComponentsModule,
    NavigationModule,
    HeaderModule,
    TimetrackingModule,
  ],
  providers: [
    Session,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {provide: LOCALE_ID, useValue: 'de-DE'},
    UserService,
    OperatorService,
    StructureService,
    FormService,
    ProtocolService,
    FileDataService,
    RestProvider,
    Data,
    AdminGuard,
    AuthGuard,
    RuebGuard,
    DisplayStructureInfoService,
    FeaturesService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AlertModalComponent,
    SelectFormModalComponent,
    PictureModalComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule {
}
