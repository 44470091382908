import {Component, EventEmitter, forwardRef, Input, OnChanges, Output} from '@angular/core';
import {ValueAccessorBase} from '../ValueAccessorBase';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {DateTime} from 'luxon';

/**
 * Generated class for the FormTextFieldComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'form-text-field-semantic',
  templateUrl: 'form-text-field-semantic.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormTextFieldSemanticComponent),
    multi: true,
  }],
})
export class FormTextFieldSemanticComponent extends ValueAccessorBase<string> implements OnChanges {

  @Input() itemLabel: string;
  @Input() enReadonly: boolean;
  @Input() dueDate: DateTime;

  @Output() changed: EventEmitter<string> = new EventEmitter<string>();

  dueDateString: string;

  constructor() {
    super();
    this.registerOnChange(value => this.changed.emit(value));
  }

  ngOnChanges(): void {
    this.dueDateString = this.dueDate ? this.dueDate.toFormat('DD') : '';
  }
}
