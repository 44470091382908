import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormItemType, JsonFormItem} from 'rueb-data-model';

export interface CreateEvent {
  formItemType: FormItemType;
  buildDifference?: boolean;
}

@Component({
  selector: 'app-form-item-edit-component',
  templateUrl: 'edit-component.html',
  styleUrls: ['edit-component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditComponent {
  readonly itemType = FormItemType;

  @Input()
  formItem: JsonFormItem;

  @Output()
  edit: EventEmitter<any> = new EventEmitter();
  @Output()
  moveUp: EventEmitter<any> = new EventEmitter();
  @Output()
  moveDown: EventEmitter<any> = new EventEmitter();
  @Output()
  delete: EventEmitter<any> = new EventEmitter();
  @Output()
  create: EventEmitter<CreateEvent> = new EventEmitter<CreateEvent>();
}
