import {NgModule} from '@angular/core';
import {OverviewTableComponent} from './overview-table/overview-table.component';
import {TableModule} from 'primeng/table';
import {CommonModule} from '@angular/common';
import {TimetrackingService} from './timetracking-service/timetracking.service';
import {
  AutoCompleteModule,
  CalendarModule,
  DialogModule,
  DropdownModule,
  InplaceModule,
  MessageModule,
  MultiSelectModule
} from 'primeng/primeng';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GerpSortButtonComponent} from './overview-table/chris-sort-button/gerp-sort-button.component';
import {TimetrackingTaskEditDialogComponent} from './timetracking-task-edit-dialog/timetracking-task-edit-dialog.component';

const components = [
  OverviewTableComponent,
  GerpSortButtonComponent,
  TimetrackingTaskEditDialogComponent,
];

@NgModule({
  declarations: components,
  exports: components,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TableModule,
    CalendarModule,
    AutoCompleteModule,
    DialogModule,
    DropdownModule,
    MultiSelectModule,
    FormsModule,
    InplaceModule,
    MessageModule,
  ],
  providers: [
    TimetrackingService
  ]
})
export class TimetrackingModule {
}
