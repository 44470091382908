import {Injectable} from '@angular/core';
import {JsonOperator, JsonStructure, Utilities} from 'rueb-data-model';
import {RestProvider} from '../providers/rest/rest.provider';
import {Observable} from 'rxjs';
import {TypedJSON} from 'typedjson';

const STRUCTURES_OPERATOR_URL = 'structures';

@Injectable()
export class OperatorService {
  constructor(private rest: RestProvider) {
  }

  put(operator: JsonOperator): Observable<any> {
    const value = Utilities.toJson(operator, JsonOperator);
    Utilities.ensureDbChannel(operator, operator.dbId);
    return this.rest.putRemoteData(value, RestProvider.OPERATORS_URL);
  }

  list(): Observable<Array<JsonOperator>> {
    let o: Observable<any> = this.rest.getAllRemoteData(RestProvider.OPERATORS_URL);
    return o.map(value => {
      const result: Array<JsonOperator> = [];
      for (const i of value as string[]) {
        const operator: JsonOperator = Utilities.fromJson(i, JsonOperator);
        result.push(operator);
      }
      return result;
    });
  }

  load(id: string): Observable<JsonOperator> {
    if (id === undefined) {
      return;
    }
    console.log('load ' + id);
    return this.rest.getRemoteData({appendGet: false, url: `${RestProvider.OPERATORS_URL}/${id}`}).map(value => {
      console.log('loading');
      return Utilities.fromJson(value, JsonOperator);
    });
  }

  getStructures(id: string): Observable<Array<JsonStructure>> {
    return this.rest.query(RestProvider.OPERATORS_URL + '/' + id + '/structures').map(value => {
      const result: Array<JsonStructure> = [];
      for (const i of value as string[]) {
        result.push(Utilities.fromJson(i, JsonStructure));
      }
      return result;
    });
  }

  getStructuresGrouped(aOperatorIds: string[]): Observable<Map<String, JsonStructure[]>> {
    return this.rest.query(`${RestProvider.OPERATORS_URL}/${STRUCTURES_OPERATOR_URL}`, aOperatorIds).map(value => {
      const jsonStructuresMap: {} = value;
      const structuresMap: Map<string, JsonStructure[]> = new Map<string, JsonStructure[]>();
      for (const curKey in jsonStructuresMap) {
        const curValue = jsonStructuresMap[curKey];
        const forms: JsonStructure[] = TypedJSON.parseAsArray(jsonStructuresMap[curKey], JsonStructure);
        structuresMap.set(curKey, forms);
      }
      return structuresMap;
    });
  }

  getAll(): Observable<JsonOperator[]> {
    return this.rest.getAllRemoteData(RestProvider.OPERATORS_URL).map(value => {
      return TypedJSON.parseAsArray(value, JsonOperator);
    });
  }

  getOwn(): Observable<JsonOperator> {
    return this.rest.getRemoteData({appendGet: false, url: `${RestProvider.OPERATORS_URL}/own`}).map(value => {
      return Utilities.fromJson(value, JsonOperator);
    });
  }

  delete(operator: JsonOperator): Observable<Object> {
    return this.rest.deleteRemoteData(operator.dbId);
  }

}
