import {Component, OnInit} from '@angular/core';
import {Session} from '../../providers/session/session';
import {OperatorService} from '../../services/operator-service';
import {JsonOperator, JsonProtocol, JsonStructure, JsonStructureInfoDocumentSection, PlaceholderSections, Utilities} from 'rueb-data-model';
import {StructInfoDocSectionVM} from '../../components/struct-info-doc-section/struct-info-doc-section.component';
import {
  InfoSummarySectionVM,
  OperatorInfoSummarySectionVM,
  StructInfoSummarySectionVM
} from '../../components/struct-info-accordion/struct-info-accordion.component';
import {StructureService} from '../../services/strcuture-service';
import {ProtocolService} from '../../services/protocol.service';
import {PlaceholderOptions} from '../../utilities/placeholder-options';
import {FileData, FileDataService} from '../../services/file-data.service';
import {PictureModal} from '../../modals/pictureModal/picture-modal.component';
import {SuiModalService} from 'ng2-semantic-ui';
import {Router} from '@angular/router';

@Component({
  selector: 'app-entry-page',
  templateUrl: 'entry-page.component.html',
  styleUrls: ['entry-page.component.css'],
})
export class EntryPageComponent implements OnInit {
  ready = false;
  structInfosReady = false;

  dataAvailable = {
    operator: false,
    structures: false,
    protocolsByStructure: false,
    protocolNotesByStructure: false,
    operatorLogo: false,
    operatorSystemPlan: false,
  };

  operator: JsonOperator;
  operatorLogo: FileData;
  operatorSystemPlan: FileData;
  sections: StructInfoDocSectionVM[] = [];
  structInfoSections: Map<String, InfoSummarySectionVM>;
  operatorInfoSections: Map<String, InfoSummarySectionVM>;
  private structures: JsonStructure[];
  private protocolsByStructure: Map<String, JsonProtocol[]>;

  constructor(public session: Session,
              private modalService: SuiModalService,
              private operatorService: OperatorService,
              private structService: StructureService,
              private protocolService: ProtocolService,
              private fileDataService: FileDataService,
              private router: Router) {

  }

  ngOnInit(): void {
    this.ready = false;
    this.structInfosReady = false;

    this.dataAvailable.operator = false;
    this.dataAvailable.structures = false;
    this.dataAvailable.protocolsByStructure = false;
    this.dataAvailable.protocolNotesByStructure = false;

    this.protocolsByStructure = new Map<String, JsonProtocol[]>();

    this.operatorService.getOwn().subscribe(aOperator => {
      this.operator = aOperator;
      this.dataAvailable.operator = true;
      this.dataLoaded();
    }, error => {
      console.error('Could not load own operator');
      console.error(error);
      this.ready = true;
    });
  }

  getOperatorSections(): InfoSummarySectionVM[] {
    if (this.operatorInfoSections) {
      return InfoSummarySectionVM.getSubSectionsAsList(this.operatorInfoSections);
    }
    return [];
  }

  getStructureSections(): InfoSummarySectionVM[] {
    if (this.structInfoSections) {
      return InfoSummarySectionVM.getSubSectionsAsList(this.structInfoSections);
    }
    return [];
  }

  showPicture(aPictureId: string, aFileName: string): void {
    if (aPictureId) {
      this.fileDataService.get(aPictureId).subscribe(aFileData => {
        this.modalService.open(new PictureModal(aFileData, {fileName: aFileName}));
      });
    }
  }

  openBetriebstagebuch() {
    this.session.isBetriebstagebuchOpen = true;
    this.router.navigate(['/structures']).catch(err => console.error('Could not open structures list', err));
  }

  private dataLoaded(): void {
    if (this.dataAvailable.operator && !this.dataAvailable.structures) {
      this.operatorInfoSections = this.updateOperatorInfos(this.operator);
      this.operatorService.getStructures(this.operator.dbId).subscribe(aStructures => {
        this.structures = aStructures;
        this.dataAvailable.structures = true;
        this.dataLoaded();
      });
    }

    if (this.dataAvailable.operator && !this.dataAvailable.operatorLogo) {
      if (this.operator.logoId) {
        this.fileDataService.get(this.operator.logoId).subscribe(fileData => {
          this.operatorLogo = fileData;
          this.dataAvailable.operatorLogo = true;
          this.dataLoaded();
        }, error => {
          console.error('Could not load operator logo');
          console.error(error);
          this.dataAvailable.operatorLogo = true;
        });
      }
    }

    if (this.dataAvailable.operator && !this.dataAvailable.operatorSystemPlan) {
      if (this.operator.systemPlan && this.operator.systemPlan.pictureId) {
        this.fileDataService.get(this.operator.systemPlan.pictureId).subscribe(fileData => {
          this.operatorSystemPlan = fileData;
          this.dataAvailable.operatorSystemPlan = true;
          this.dataLoaded();
        }, error => {
          console.error('Could not load operator system plan');
          console.error(error);
          this.dataAvailable.operatorSystemPlan = true;
        });
      }
    }

    if (this.dataAvailable.operator && this.dataAvailable.operatorLogo && this.dataAvailable.operatorSystemPlan) {
      this.ready = true;
    }

    if (this.dataAvailable.structures && !this.dataAvailable.protocolsByStructure) {
      const structIds = this.structures.map(curStructure => curStructure.dbId);
      this.structService.getProtocolsGrouped(structIds).subscribe(protocolsMap => {
        this.protocolsByStructure = protocolsMap;
        this.dataAvailable.protocolsByStructure = true;
        this.dataAvailable.protocolNotesByStructure = true;
        this.dataLoaded();
      });
    }

    if (this.dataAvailable.protocolNotesByStructure) {
      this.structInfoSections = this.updateStructInfos(this.structures);
      this.structInfosReady = true;
    }
  }

  private updateOperatorInfos(aOperator: JsonOperator): Map<String, InfoSummarySectionVM> {
    const sectionsMap: Map<String, InfoSummarySectionVM> = new Map<String, InfoSummarySectionVM>();
    if (aOperator.infoDoc) {
      const vmFactory = () => {
        const vm = new OperatorInfoSummarySectionVM();
        vm.operator = aOperator;
        return vm;
      };
      this.convertSectionToVM(sectionsMap, aOperator.infoDoc.sections, aOperator.name, {}, vmFactory, false);
    }
    return sectionsMap;
  }

  private updateStructInfos(aStructures: JsonStructure[]): Map<String, InfoSummarySectionVM> {
    const sectionsMap: Map<String, InfoSummarySectionVM> = new Map<String, InfoSummarySectionVM>();
    for (const curStructure of aStructures) {
      if (curStructure.infoDocument) {
        const protocols = this.protocolsByStructure.get(curStructure.dbId);
        const placeholderOptions = {
          protocols: protocols ? protocols : [],
        };
        const vmFactory = () => {
          const vm = new StructInfoSummarySectionVM();
          vm.structure = curStructure;
          return vm;
        };
        this.convertSectionToVM(sectionsMap, curStructure.infoDocument.sections, curStructure.name, placeholderOptions, vmFactory, true);
      }
    }
    return sectionsMap;
  }

  private convertSectionToVM(aSectionsMap: Map<String, InfoSummarySectionVM>, aSections: JsonStructureInfoDocumentSection[],
                             aInfoSectionLabel: string, aPlaceholderOptions: PlaceholderOptions,
                             aVmFactory: () => InfoSummarySectionVM, aCreateSubSection: boolean): void {
    for (const curSection of aSections) {
      this.sectionToVM(aSectionsMap, curSection, aInfoSectionLabel, aPlaceholderOptions, aVmFactory, aCreateSubSection);
    }
  }

  private sectionToVM(aSectionsMap: Map<String, InfoSummarySectionVM>, curSection: JsonStructureInfoDocumentSection,
                      aInfoSectionLabel: string, aPlaceholderOptions: PlaceholderOptions, aVmFactory: () => InfoSummarySectionVM,
                      aCreateSubSection: boolean) {
    let sectionVM: InfoSummarySectionVM = aSectionsMap.get(curSection.label);
    if (sectionVM == null) {
      sectionVM = aVmFactory();
      sectionVM.label = curSection.label;
      aSectionsMap.set(sectionVM.label, sectionVM);
    }

    if (Utilities.notEmpty(curSection.subSections)) {
      this.convertSectionToVM(sectionVM.subSections, curSection.subSections, aInfoSectionLabel, aPlaceholderOptions, aVmFactory,
        aCreateSubSection);
    }

    let infoTargetSection: InfoSummarySectionVM;
    if (aCreateSubSection) {
      infoTargetSection = aVmFactory();
      infoTargetSection.label = aInfoSectionLabel;
    } else {
      infoTargetSection = sectionVM;
    }

    if (curSection.placeholderFor) {
      infoTargetSection.placeholderFor = curSection.placeholderFor;
      let placheholderDataAvailable = false;
      switch (curSection.placeholderFor) {
        case PlaceholderSections.PROTOCOL_PICTURES:
          const protocolPicturesList = StructInfoDocSectionVM.createProtocolPicturesPlaceholderData(aPlaceholderOptions.protocols);
          infoTargetSection.placeholderData = protocolPicturesList;
          placheholderDataAvailable = Utilities.notEmpty(protocolPicturesList);
          break;
        default:
          break;
      }
      if (placheholderDataAvailable && aCreateSubSection) {
        sectionVM.subSections.set(infoTargetSection.label, infoTargetSection);
      }
    } else if (Utilities.notEmpty(curSection.infos)) {
      infoTargetSection.infos = curSection.infos;
      if (aCreateSubSection) {
        sectionVM.subSections.set(infoTargetSection.label, infoTargetSection);
      }
    }
  }
}
