import {Component, Input, OnInit} from '@angular/core';
import {JsonProtocolPicture} from 'rueb-data-model';
import {SuiModalService} from 'ng2-semantic-ui';
import {PictureModal} from '../../modals/pictureModal/picture-modal.component';
import {FileData, FileDataService} from '../../services/file-data.service';

export class ProtocolPictureVM {
  picture: JsonProtocolPicture;
  pictureData: FileData;
  pictureLoaded: boolean;
  pictureLabel: string;
  expanded: boolean;
}

@Component({
  selector: 'app-protocol-pictures-placeholder',
  templateUrl: './protocol-pictures-placeholder.component.html',
  styleUrls: ['./protocol-pictures-placeholder.component.css']
})
export class ProtocolPicturesPlaceholderComponent implements OnInit {

  @Input()
  protocolPictures: ProtocolPictureVM[];
  @Input()
  displayInline: boolean;

  constructor(private modalService: SuiModalService, private fileDataService: FileDataService) {
  }

  ngOnInit() {
  }

  pictureClicked(aPicture: ProtocolPictureVM) {
    if (this.displayInline) {
      if (aPicture.pictureLoaded) {
        aPicture.expanded = !aPicture.expanded;
      } else if (!aPicture.expanded) {
        this.fileDataService.get(aPicture.picture.pictureId).subscribe({
          next: aFileData => aPicture.pictureData = aFileData,
          error: err => {
            console.error(`Error loading picture ${aPicture.picture.pictureId}`, err);
            aPicture.pictureLoaded = true;
            aPicture.expanded = true;
          },
          complete: () => {
            aPicture.pictureLoaded = true;
            aPicture.expanded = true;
          }
        });
      }
    } else {
      if (aPicture.pictureLoaded) {
        this.modalService.open(
          new PictureModal(aPicture.pictureData, {fileName: aPicture.pictureLabel}));
      } else {
        this.fileDataService.get(aPicture.picture.pictureId).subscribe({
          next: aFileData => aPicture.pictureData = aFileData,
          error: err => {
            console.error(`Error loading picture ${aPicture.picture.pictureId}`, err);
            aPicture.pictureLoaded = true;
          },
          complete: () => {
            aPicture.pictureLoaded = true;
            this.modalService.open(
              new PictureModal(aPicture.pictureData, {fileName: aPicture.pictureLabel}));
          }
        });
      }
    }
  }

}
