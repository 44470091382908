import {Component, OnChanges, SimpleChanges} from '@angular/core';
import {JsonOperator} from 'rueb-data-model';
import {Session} from '../../providers/session/session';
import {StructureListDataService} from './structure-list-data/structure-list-data.service';

@Component({
  selector: 'page-list',
  templateUrl: 'structurelist.html',
  styleUrls: ['structurelist.css'],
  providers: [
    StructureListDataService
  ]
})
export class StructureListComponent implements OnChanges {
  canEdit = false;

  ready = false;

  operators: JsonOperator[] = [];

  constructor(private session: Session,
              private dataService: StructureListDataService) {
    this.canEdit = this.session.isAdmin;
    this.refreshList();
  }

  ngOnChanges(aChanges: SimpleChanges): void {
    this.refreshList();
  }

  refreshList() {
    this.ready = false;

    this.dataService.loadOperators().subscribe(aOperators => {
      this.operators = aOperators.sort((a, b) => a.name.localeCompare(b.name));
      this.ready = true;
    });
  }
}
