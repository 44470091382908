import {Injectable} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {NavigationService} from '../navigation.service';

@Injectable()
export class NavOperatorListGuard implements Resolve<MenuItem[]> {


  constructor(private navigationService: NavigationService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MenuItem[]> | Promise<MenuItem[]> | MenuItem[] {
    this.navigationService.clearItems();
    const aItem = {label: 'Betreiber', routerLink: ['/operators']};
    this.navigationService.addItem(aItem);
    return [aItem];
  }

}
