import {Injectable} from '@angular/core';
import {NavigationService} from '../navigation.service';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {MenuItem} from 'primeng/api';
import {Observable} from 'rxjs';
import {USER_ID_ROUTE_PARAM} from '../../app/route-parameters';
import {UserService} from '../../services/user-service';

@Injectable()
export class NavUsersDetailGuard implements Resolve<MenuItem[]> {
  constructor(private navigationService: NavigationService, private userService: UserService) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MenuItem[]> | Promise<MenuItem[]> | MenuItem[] {
    const userId = route.paramMap.get(USER_ID_ROUTE_PARAM);
    return this.userService.load(userId).map(aUser => {
      return [
        {label: 'Benutzer', routerLink: ['/users']},
        {label: aUser.name, routerLink: ['/users', aUser.dbId]},
      ];
    }).do(aItems => {
      this.navigationService.clearItems();
      this.navigationService.addItems(...aItems);
    });
  }
}
