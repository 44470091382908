import {CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';
import {Session} from '../session/session';

@Injectable()
export class RuebGuard implements CanActivate {
  constructor(private session: Session) {
  }

  canActivate(): boolean {
    return this.session.isBetriebstagebuchAvailable;
  }
}
