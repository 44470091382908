import {NgModule} from '@angular/core';
import {BetriebstagebuchHeaderComponent} from './betriebstagebuch-header/betriebstagebuch-header.component';
import {DefaultHeaderComponent} from './default-header/default-header.component';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';

const components = [
  BetriebstagebuchHeaderComponent,
  DefaultHeaderComponent
];

@NgModule({
  declarations: components,
  imports: [
    RouterModule,
    CommonModule
  ],
  exports: components
})
export class HeaderModule {

}
