import {Injectable} from '@angular/core';
import {NavigationService} from '../navigation.service';
import {MenuItem} from 'primeng/api';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {INFO_ID_ROUTE_PARAM, STRUCTURE_ID_ROUTE_PARAM} from '../../app/route-parameters';
import {StructureService} from '../../services/strcuture-service';
import {Session} from '../../providers/session/session';

@Injectable()
export class NavStructuresStructureInfosViewGuard implements Resolve<MenuItem[]> {

  constructor(private navigationService: NavigationService,
              private structureService: StructureService,
              private session: Session) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MenuItem[]> | Promise<MenuItem[]> | MenuItem[] {
    const structureId = route.paramMap.get(STRUCTURE_ID_ROUTE_PARAM);
    const infoId = route.paramMap.get(INFO_ID_ROUTE_PARAM);

    if (this.session.isBetriebstagebuchOpen) {
      return this.structureService.getById(structureId).map(aStructure => {
        const items: MenuItem[] = [
          {label: 'Bauwerke', routerLink: ['/structures']},
          {label: aStructure.name, routerLink: ['/structures', aStructure.dbId]},
          {
            label: 'Aktuelle Info',
            routerLink: ['/structures', aStructure.dbId, 'infos', infoId]
          },
        ];
        return items;
      }).do(aItems => {
        this.navigationService.clearItems();
        this.navigationService.addItems(...aItems);
      });
    } else {
      const items = [{label: 'Startseite', routerLink: ['']}];
      this.navigationService.clearItems();
      this.navigationService.addItems(...items);
      return items;
    }
  }
}
