import {Component, OnInit} from '@angular/core';
import {JsonUser} from 'rueb-data-model/src/json-user';
import {UserService} from '../../services/user-service';
import {ActivatedRoute, Router} from '@angular/router';
import {JsonOperator, Utilities} from 'rueb-data-model';
import {OperatorService} from '../../services/operator-service';
import {hashSync} from 'bcryptjs';
import {HttpErrorResponse} from '@angular/common/http';
import {SuiModalService} from 'ng2-semantic-ui';
import {AlertModal} from '../../modals/confirm-modal';
import {USER_ID_ROUTE_PARAM} from '../../app/route-parameters';

@Component({
  selector: 'app-user-create',
  templateUrl: 'user-create.html'
})
export class UserCreateComponent implements OnInit {
  title: string;
  user: JsonUser;
  operators: Array<JsonOperator>;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private userService: UserService,
              private operatorService: OperatorService, private modalService: SuiModalService) {
  }

  ngOnInit(): void {
    const userId = this.activatedRoute.snapshot.params[USER_ID_ROUTE_PARAM];
    this.user = JsonUser.empty();
    this.operatorService.list().subscribe(u => {
      this.operators = u;
    });
    if (userId == null) {
      this.title = 'Benutzer anlegen';
    } else {
      this.title = 'Benutzer bearbeiten';
      this.userService.load(userId).subscribe(u => {
        this.user = u;
        console.debug(u);
      });
    }
  }

  save() {
    Utilities.ensurePublicChannel(this.user);
    console.debug(this.user);
    this.user.login = this.user.login.trim();
    this.user.password = hashSync(this.user.password.trim());
    console.debug(this.user);
    this.userService.put(this.user).subscribe(() => {
      this.router.navigate(['../'], {relativeTo: this.activatedRoute});
    }, (error: HttpErrorResponse) => {
      if (error.status === 422) {
        this.modalService.open(new AlertModal('Benutzer konnte nicht gespeichert werden', 'Der Login ist bereits vergeben'));
      }
    });
  }
}
