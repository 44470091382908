import {Component, OnInit} from '@angular/core';
import {UserService} from '../../services/user-service';
import {JsonUser} from 'rueb-data-model/src/json-user';
import {Utilities} from 'rueb-data-model';
import {SuiModalService} from 'ng2-semantic-ui';
import {AlertModal} from '../../modals/confirm-modal';

@Component({
  selector: 'app-admin',
  templateUrl: 'admin.html'
})
export class AdminComponent implements OnInit {
  users: Array<JsonUser>;

  ready: boolean;

  constructor(private userService: UserService, private modalService: SuiModalService) {
  }

  ngOnInit(): void {
    this.ready = false;
    this.userService.list().subscribe(u => {
      this.users = u;
      this.ready = true;
    });
  }

  deleteUser(aUser: JsonUser): void {
    this.modalService.open(new AlertModal('Löschen', `Möchten Sie den Benutzer ${aUser.name} wirklich löschen?`)).onApprove(() => {
      this.userService.delete(aUser).subscribe({
        next: () => {
          Utilities.removeElementFromArray(this.users, aUser);
        },
        error: error => {
          console.error('Could not delete user');
          console.error(error);
        }
      });
    });
  }
}
