import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Session} from '../session/session';
import {RestProvider} from '../rest/rest.provider';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private session: Session, private router: Router, private rest: RestProvider) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.session.isLoggedIn) {
      return true;
    }

    this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
    return false;
  }
}
