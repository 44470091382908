import {Injectable} from '@angular/core';
import {RestProvider} from '../providers/rest/rest.provider';
import {JsonForm, JsonTask, MetaType, Utilities} from 'rueb-data-model';
import {Observable} from 'rxjs';

const HEADING_SUGGESTIONS_URL = 'headingsuggestions';

@Injectable()
export class FormService {

  constructor(private restProvider: RestProvider) {
  }

  put(aForm: JsonForm): Observable<any> {
    const jsonForm = Utilities.toJson(aForm, JsonForm);
    return this.restProvider.putRemoteData(jsonForm, `${RestProvider.STRUCT_URL}/${aForm.structureId}/forms/${aForm.dbId}`);
  }

  load(aFormId: string, aStructureId: string): Observable<JsonForm> {
    return this.restProvider.getRemoteData({url: `${RestProvider.STRUCT_URL}/${aStructureId}/forms/${aFormId}`, appendGet: false})
      .map(value => {
        return Utilities.fromJson(value, JsonForm);
      });
  }

  delete(form: JsonForm): Observable<any> {
    return this.restProvider.deleteRemoteDataRest(`${RestProvider.STRUCT_URL}/${form.structureId}/forms/${form.dbId}`);
  }

  getHeadingSuggestions(): Observable<string[]> {
    return this.restProvider.query(`${RestProvider.FORM_URL}/${HEADING_SUGGESTIONS_URL}`);
  }
}
