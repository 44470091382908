import {Injectable} from '@angular/core';
import {JsonForm, JsonFormItem, JsonOperator, JsonProtocol, JsonStructure, JsonTask, MetaType, Utilities} from 'rueb-data-model';
import {RestProvider} from '../../../providers/rest/rest.provider';
import {Observable} from 'rxjs';
import {OperatorService} from '../../../services/operator-service';
import {StructureService} from '../../../services/strcuture-service';
import {FormService} from '../../../services/form.service';
import {DateTime} from 'luxon';

export interface StructureListDataAvailable {
  structures: boolean;
  protocols: boolean;
  dueDates: boolean;
}

export class StructureVM {
  structure: JsonStructure;
  nextInspectionDueString: string;
  nextDueForm: JsonForm;
  lastProtocolDateString: string;

  constructor(structure: JsonStructure) {
    this.structure = structure;
  }

  _nextDueTask: JsonTask;

  get nextDueTask(): JsonTask {
    return this._nextDueTask;
  }

  set nextDueTask(aTask: JsonTask) {
    this._nextDueTask = aTask;
  }

  private _nextInspectionDue: DateTime;

  get nextInspectionDue(): DateTime {
    return this._nextInspectionDue;
  }

  set nextInspectionDue(aDate: DateTime) {
    this._nextInspectionDue = aDate;
    if (this._nextInspectionDue) {
      this.nextInspectionDueString = aDate.toFormat('DD');
    }
  }

  private _lastProtocol: JsonProtocol;

  get lastProtocol(): JsonProtocol {
    return this._lastProtocol;
  }

  set lastProtocol(aProtocol: JsonProtocol) {
    this._lastProtocol = aProtocol;
    if (this._lastProtocol) {
      this.lastProtocolDateString = this._lastProtocol.createdDate.toFormat('DD');
    }
  }
}

@Injectable()
export class StructureListDataService {
  private _structuresByOperator: Map<String, JsonStructure[]>;
  private _maxProtocolByStructure: Map<String, JsonProtocol>;
  private _nextFormByStructure: Map<String, JsonForm>;
  private _nextTaskByForm: Map<String, JsonTask>;

  constructor(private restProvider: RestProvider,
              private operatorService: OperatorService,
              private structService: StructureService,
              private formService: FormService) {
    this._structuresByOperator = new Map<String, JsonStructure[]>();
    this._maxProtocolByStructure = new Map<String, JsonProtocol>();
    this._nextFormByStructure = new Map<String, JsonForm>();
    this._nextTaskByForm = new Map<String, JsonTask>();
  }

  private _operators: JsonOperator[];

  public get operators(): JsonOperator[] {
    return this._operators;
  }

  public get structures(): JsonStructure[] {
    const structureLists = Array.from(this._structuresByOperator.values());
    return Utilities.flatten(structureLists);
  }

  public loadOperators(): Observable<JsonOperator[]> {
    return this.operatorService.getAll().map(aOperators => {
      this.operatorsLoaded(aOperators);
      return aOperators;
    });
  }

  public loadDataFor(aOperator: JsonOperator): Observable<StructureListDataAvailable> {
    return Observable.create(aObserver => {
      const dataAvailable: StructureListDataAvailable = {
        structures: false,
        protocols: false,
        dueDates: false
      };

      this.operatorService.getStructures(aOperator.dbId).flatMap(aStructureList => {
        this.structuresLoaded(aStructureList, aOperator);
        dataAvailable.structures = true;
        aObserver.next(dataAvailable);
        const structureIds: string[] = aStructureList.map(aStructure => aStructure.dbId);
        return this.structService.getProtocolsGrouped(structureIds);
      }).flatMap(aProtocolMap => {
        this.protocolsLoaded(aProtocolMap);
        dataAvailable.protocols = true;
        aObserver.next(dataAvailable);
        const structureIds: string[] = this._structuresByOperator.get(aOperator.dbId).map(aStructure => aStructure.dbId);
        return this.structService.getFormsGrouped(structureIds, true);
      }).map(aFormMap => {
        this.formsLoaded(aFormMap);
        dataAvailable.dueDates = true;
        aObserver.next(dataAvailable);
      }).subscribe({
        error: error => aObserver.error(error),
        complete: () => aObserver.complete()
      });
    });
  }

  public structuresForOperator(aOperator: JsonOperator): Observable<JsonStructure[]> {
    const structures: JsonStructure[] = this._structuresByOperator.get(aOperator.dbId);
    if (structures != null) {
      return Observable.of(structures);
    } else {
      return Observable.empty();
    }
  }

  public structVmsForOperator(aOperator: JsonOperator): Observable<StructureVM[]> {
    const structures: JsonStructure[] = this._structuresByOperator.get(aOperator.dbId);
    if (structures) {
      const structVms: StructureVM[] = structures.map(aStructure => {
        const structVm: StructureVM = new StructureVM(aStructure);
        structVm.lastProtocol = this._maxProtocolByStructure.get(aStructure.dbId);
        structVm.nextDueForm = this._nextFormByStructure.get(aStructure.dbId);
        if (structVm.nextDueForm) {
          structVm.nextDueTask = this._nextTaskByForm.get(structVm.nextDueForm.dbId);
          if (structVm.nextDueTask) {
            structVm.nextInspectionDue = structVm.nextDueTask.dueDate;
          }
        }
        return structVm;
      });
      return Observable.of(structVms);
    } else {
      return Observable.empty();
    }
  }

  public deleteStructure(aStructure: JsonStructure) {
    return this.structService.delete(aStructure);
  }


  private formsLoaded(aFormMap: Map<String, JsonForm[]>) {
    let allForms: JsonForm[] = [];
    aFormMap.forEach(value => {
      allForms = allForms.concat(value);
    });
    let allFormItems: JsonFormItem[] = [];

    allForms.forEach(aForm => {
      allFormItems = allFormItems.concat(Utilities.flattenForm(aForm));
    });
    this.dueDatesLoaded(aFormMap);
  }

  private dueDatesLoaded(aFormMap: Map<String, JsonForm[]>) {
    const nextFormByStructure: Map<String, JsonForm> = new Map<String, JsonForm>();
    const nextTaskByForm: Map<String, JsonTask> = new Map<String, JsonTask>();

    for (const curStructId of Array.from(aFormMap.keys())) {
      const forms: JsonForm[] = aFormMap.get(curStructId);
      if (forms) {
        const nextDueForm = Utilities.getNextDueFormNew(forms);

        if (nextDueForm) {
          nextFormByStructure.set(curStructId, nextDueForm);

          const tasks = Utilities.flattenForm(nextDueForm)
            .filter(curFormItem => curFormItem.metaInfo.type === MetaType.TASK).map(curTask => curTask as JsonTask);
          const nextDueTask = Utilities.getNextDueTaskNew(tasks);
          if (nextDueTask) {
            nextTaskByForm.set(nextDueForm.dbId, nextDueTask);
          }
        }
      }
    }
    nextFormByStructure.forEach((aForm, aStructureId) => {
      this._nextFormByStructure.set(aStructureId, aForm);
    });
    nextTaskByForm.forEach((aTask, aFormId) => {
      this._nextTaskByForm.set(aFormId, aTask);
    });
  }

  private protocolsLoaded(aProtocolMap: Map<String, JsonProtocol[]>) {
    const maxProtocolByStructure: Map<String, JsonProtocol> = new Map<String, JsonProtocol>();
    aProtocolMap.forEach((aProtocols: JsonProtocol[], aStructureId: String) => {
      let maxProtocol: JsonProtocol;
      for (const curProtocol of aProtocols) {
        if (!maxProtocol || maxProtocol.createdDate < curProtocol.createdDate) {
          maxProtocol = curProtocol;
        }
      }
      if (maxProtocol) {
        maxProtocolByStructure.set(aStructureId, maxProtocol);
      }
    });
    maxProtocolByStructure.forEach((aProtocol, aStructureId) => {
      this._maxProtocolByStructure.set(aStructureId, aProtocol);
    });
  }

  private structuresLoaded(aStructures: JsonStructure[], aOperator: JsonOperator) {
    this._structuresByOperator.set(aOperator.dbId, aStructures);
  }

  private operatorsLoaded(aOperators: JsonOperator[]) {
    this._operators = aOperators;
  }


}
