import {Injectable} from '@angular/core';

@Injectable()
export class Session {
  isLoggedIn = false;
  isAdmin = false;
  isUser = false;
  isTimetrackingAvailable = false;
  isBetriebstagebuchAvailable = false;
  isBetriebstagebuchOpen = false;
  betriebstagebuchName = 'Betriebstagebuch';
}
