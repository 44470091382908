import {Injectable} from '@angular/core';
import {NavigationService} from '../navigation.service';
import {MenuItem} from 'primeng/api';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {PROTOCOL_ID_ROUTE_PARAM, STRUCTURE_ID_ROUTE_PARAM} from '../../app/route-parameters';
import {StructureService} from '../../services/strcuture-service';
import {ProtocolService} from '../../services/protocol.service';

@Injectable()
export class NavStructuresStructureProtocolsDetailGuard implements Resolve<MenuItem[]> {

  constructor(private navigationService: NavigationService,
              private structureService: StructureService,
              private protocolService: ProtocolService) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MenuItem[]> | Promise<MenuItem[]> | MenuItem[] {
    const structureId = route.paramMap.get(STRUCTURE_ID_ROUTE_PARAM);
    const protocolId = route.paramMap.get(PROTOCOL_ID_ROUTE_PARAM);

    const structure$ = this.structureService.getById(structureId);
    const protocol$ = this.protocolService.load(protocolId, structureId);

    return Observable.zip(structure$, protocol$, (aStructure, aProtocol) => {
      const items: MenuItem[] = [
        {label: 'Bauwerke', routerLink: ['/structures']},
        {label: aStructure.name, routerLink: ['/structures', aStructure.dbId]},
        {label: aProtocol.createdDate.toFormat('DD HH:mm'), routerLink: ['/structures', aStructure.dbId, 'protocols', aProtocol.dbId]},
      ];
      return items;
    }).do(aItems => {
      this.navigationService.clearItems();
      this.navigationService.addItems(...aItems);
    });
  }
}
