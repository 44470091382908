import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {SuiModule} from 'ng2-semantic-ui';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {StructInfoDocSectionComponent} from './struct-info-doc-section/struct-info-doc-section.component';
import {StructInfoAccordionComponent} from './struct-info-accordion/struct-info-accordion.component';
import {ProtocolPicturesPlaceholderComponent} from './protocol-pictures-placeholder/protocol-pictures-placeholder.component';
import {InfoViewComponent} from './info-view/info-view.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {MenubarModule} from 'primeng/menubar';

const parts = [
  StructInfoDocSectionComponent,
  StructInfoAccordionComponent,
  ProtocolPicturesPlaceholderComponent,
  InfoViewComponent,
];

@NgModule({
  declarations: parts,
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    PdfViewerModule,
    SuiModule,
    BrowserAnimationsModule,
    MenubarModule,
  ],
  entryComponents: [],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  exports: parts,
})
export class CommonComponentsModule {
}
