import {Component, OnInit} from '@angular/core';
import {SuiModalService} from 'ng2-semantic-ui';
import {JsonOperator} from 'rueb-data-model';
import {OperatorService} from '../../services/operator-service';
import {AlertModal} from '../../modals/confirm-modal';

@Component({
  selector: 'app-operator-list',
  templateUrl: 'operator-list.html'
})
export class OperatorListComponent implements OnInit {
  items: Array<JsonOperator>;

  ready: boolean;

  constructor(public modalService: SuiModalService, private operatorService: OperatorService) {
  }

  ngOnInit(): void {
    this.ready = false;
    this.operatorService.list().subscribe(data => {
      console.log('data = ' + data);
      this.items = data as Array<JsonOperator>;
      this.ready = true;
    });
  }

  delete(aOperator: JsonOperator) {
    this.modalService.open(new AlertModal('Löschen', `Möchten Sie den Betreiber ${aOperator.name} wirklich löschen?`)).onApprove(() => {
      this.operatorService.delete(aOperator).subscribe(
        val => {
          console.log('DELETE call successful value returned in body', val);
        },
        response => {
          console.log('DELETE call in error', response);
        },
        () => {
          console.log('The DELETE observable is now completed.');
          this.ngOnInit();
        }
      );
    });
  }
}
