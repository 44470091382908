import {Observable} from 'rxjs';
import {RestProvider} from '../providers/rest/rest.provider';
import {Injectable} from '@angular/core';
import {JsonUser} from 'rueb-data-model/src/json-user';
import {Utilities} from 'rueb-data-model';

@Injectable()
export class UserService {
  constructor(private rest: RestProvider) {
  }

  put(user: JsonUser): Observable<any> {
    const jsonUser = Utilities.toJson(user, JsonUser);
    return this.rest.putRemoteData(jsonUser, RestProvider.USER_URL);
  }

  list(): Observable<Array<JsonUser>> {
    const o: Observable<any> = this.rest.getAllRemoteData(RestProvider.USER_URL);
    return o.map(value => {
      const result: Array<JsonUser> = [];
      for (const i of value as string[]) {
        const operator: JsonUser = Utilities.fromJson(i, JsonUser);
        result.push(operator);
      }
      return result;
    });
  }

  load(id: string): Observable<JsonUser> {
    return this.rest.getRemoteData({appendGet: false, url: `${RestProvider.USER_URL}/${id}`}).map(value => {
      return Utilities.fromJson(value, JsonUser);
    });
  }

  delete(aUser: JsonUser): Observable<any> {
    return this.rest.deleteRemoteData(aUser.dbId);
  }
}
