import {Component} from '@angular/core';
import {Session} from '../providers/session/session';
import {RestProvider} from '../providers/rest/rest.provider';
import {NavigationEnd, Router} from '@angular/router';
import {SuiLocalizationService} from 'ng2-semantic-ui';
import de from 'ng2-semantic-ui/locales/de';
import {OperatorService} from '../services/operator-service';

const NOT_BETRIEBSTAGEBUCH_URLS = [/\/$/, /\/login$/];
const NO_CHANGE_URLS = [/\/structures\/info\/.*/];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

  constructor(public session: Session,
              private rest: RestProvider,
              private router: Router,
              localService: SuiLocalizationService,
              private operatorService: OperatorService) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const urlAfterRedirects = (event as NavigationEnd).urlAfterRedirects;
        console.log('Navigated to ' + urlAfterRedirects);
        const paramsIdx = urlAfterRedirects.indexOf('?');
        const urlWithourParams = paramsIdx > 0 ? urlAfterRedirects.substring(0, paramsIdx) : urlAfterRedirects;

        if (urlWithourParams.endsWith('logout')) {
          this.logout();
        }
      }
    });
    this.rest.getRoleHint().subscribe(roleHint => {
      this.session.isUser = true;
      this.session.isAdmin = (roleHint['role_hint'].toLowerCase() === 'admin');
      this.operatorService.getOwn().subscribe(aOperator => {
        if (aOperator.betriebstagebuchCustomName) {
          this.session.betriebstagebuchName = aOperator.betriebstagebuchCustomName;
        }
      });
    }, () => {
      localStorage.removeItem('id_token');
    });
    localService.load('de', de);
    localService.setLanguage('de');
  }

  private logout(): void {
    localStorage.removeItem('id_token');
    this.session.isUser = false;
    this.session.isAdmin = false;
    this.session.isBetriebstagebuchAvailable = false;
    this.session.isBetriebstagebuchOpen = false;
    this.session.betriebstagebuchName = 'Betriebstagebuch';
    this.session.isLoggedIn = false;
  }
}
