import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FileData, FileDataService} from '../../services/file-data.service';
import {DisplayStructureInfoService} from './service/display-structure-info.service';
import {Subject} from 'rxjs';
import {INFO_ID_ROUTE_PARAM} from '../../app/route-parameters';

export const DISPLAY_STRUCTURE_INFO_PARAM_DATA = 'data';
export const DISPLAY_STRUCTURE_INFO_PARAM_DATA_TYPE = 'dataType';


@Component({
  selector: 'page-display-structure-info',
  templateUrl: 'display-structure-info.html',
})
export class DisplayStructureInfoPage implements OnInit, OnDestroy {

  fileName: string;
  dataId: string;

  fileData: FileData;

  ready = false;

  private _unsubscribe: Subject<void> = new Subject();

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private fileDataSerivce: FileDataService,
              private displayInfoService: DisplayStructureInfoService) {

  }

  ngOnInit(): void {
    this.displayInfoService.curInfo.takeUntil(this._unsubscribe).subscribe(aInfo => {
      if (aInfo) {
        if (aInfo.filename) {
          this.router.navigate(['../', aInfo.dataId, {filename: aInfo.filename}], {relativeTo: this.activatedRoute});
        } else {
          this.router.navigate(['../', aInfo.dataId], {relativeTo: this.activatedRoute});
        }
      }
    });
    this.activatedRoute.paramMap.takeUntil(this._unsubscribe).subscribe(aParams => {
      this.fileName = aParams.get('filename');
      const dataId: string = aParams.get(INFO_ID_ROUTE_PARAM);
      if (this.dataId !== dataId) {
        this.dataId = dataId;
        this.ready = false;
        this.fileDataSerivce.get(dataId).takeUntil(this._unsubscribe).subscribe(fileData => {
          this.fileData = fileData;
          this.ready = true;
        });
      }
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  nextInfo() {
    this.displayInfoService.next();
  }

  previousInfo() {
    this.displayInfoService.previous();
  }
}
