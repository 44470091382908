import {NgModule} from '@angular/core';
import {RoundPipe} from './round/round.pipe';
import {FormHeadingSemanticComponent} from './form-heading/form-heading-semantic';
import {FormsModule} from '@angular/forms';
import {FormCounterSemanticComponent} from './form-counter/form-counter-semantic';
import {FormCheckboxSemanticComponent} from './form-checkbox/form-checkbox-semantic';
import {FormTextAreaSemanticComponent} from './form-text-area/form-text-area-semantic';
import {FormTextFieldSemanticComponent} from './form-text-field/form-text-field-semantic';
import {CommonModule} from '@angular/common';
import {SuiModule} from 'ng2-semantic-ui';

const parts = [
  RoundPipe,
  FormHeadingSemanticComponent,
  FormCounterSemanticComponent,
  FormCheckboxSemanticComponent,
  FormTextAreaSemanticComponent,
  FormTextFieldSemanticComponent];

@NgModule({
  declarations: [parts],
  imports: [FormsModule, CommonModule, SuiModule],
  exports: [parts]
})
export class FormComponentsModule {
}
