import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {DateTime} from 'luxon';
import {ValueAccessorBase} from '../ValueAccessorBase';
import {ICounterValue} from 'rueb-data-model/src/form-value-interfaces';
import {RoundPipe} from '../round/round.pipe';
import {DatepickerMode, IDatepickerLocaleValues} from 'ng2-semantic-ui';
import {RuebInterval, Utilities} from 'rueb-data-model';

export interface IComponentValue {
  isoDate?: string;
  dateString?: string;
  count?: number;
  diff?: number;
  period?: number;
  history?: IComponentHistoryValue[];
}

export interface IComponentHistoryValue {
  date: DateTime;
  count: number;
  diff?: number;
  period?: number;
}

/**
 * Generated class for the FormCounterComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'form-counter-semantic',
  templateUrl: 'form-counter-semantic.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormCounterSemanticComponent),
    multi: true,
  }],
  styleUrls: ['form-counter-semantic.css']
})
export class FormCounterSemanticComponent extends ValueAccessorBase<ICounterValue> implements OnInit, OnChanges {
  @Input() itemLabel: string;
  @Input() enReadonly: boolean;
  @Input() dueDate: DateTime;
  @Input() taskInterval: RuebInterval;
  @Input() counterValue: ICounterValue;
  @Input() unit: string;
  @Input() heading: string;
  @Input() buildDifference: boolean;

  @Output() changed: EventEmitter<ICounterValue> = new EventEmitter<ICounterValue>();

  dueDateDue: boolean;
  dueDateString: string;

  curValue: IComponentValue;
  datepickerMode = DatepickerMode.Date;
  pickerOverrides: Partial<IDatepickerLocaleValues> = {
    formats: {
      date: 'DD.MM.YYYY',
      datetime: 'DD.MM.YYYY, hh:mm',
      month: 'MM',
      time: 'hh:mm',
      year: 'YYYY'
    }
  };


  constructor() {
    super();
    const now: Date = new Date();
    const nowLuxon: DateTime = DateTime.fromJSDate(now);

    this.registerOnChange(value => this.changed.emit(value));

    this.updateComponentValue({
      date: null,
      count: null,
      history: []
    });
  }

  labelHasChanged() {
    this.labelChanged.emit(this.itemLabel);
  }

  valueChanged() {
    const currentCount: number = Number(this.curValue.count);

    const updatedValue: ICounterValue = {
      date: this.curValue.isoDate,
      count: Number.isNaN(currentCount) ? this.value.count : currentCount,
      history: this.value ? this.value.history : [],
    };

    if (this.value == null || (this.value.date !== updatedValue.date || this.value.count !== updatedValue.count)) {
      this.updateComponentValue(updatedValue);
      this.value = updatedValue;
    }
  }

  ngOnInit(): void {
    this.writeValue(this.counterValue);
  }

  ngOnChanges(): void {
    this.dueDateDue = Utilities.isDueDateDue(this.dueDate);
    this.dueDateString = this.dueDate ? this.dueDate.toFormat('DD') : '';
  }

  writeValue(value: ICounterValue): void {
    super.writeValue(value);
    this.updateComponentValue(value);
  }

  private updateComponentValue(value: ICounterValue) {
    let lastDate: DateTime;
    let lastCount: number;

    const updatedComponentValue: IComponentValue = {};

    if (value != null && value.history != null && value.history.length > 0) { // there exists at least one entry in the history
      const lastRead: ICounterValue = value.history[value.history.length - 1];
      lastDate = DateTime.fromISO(lastRead.date);
      lastCount = lastRead.count;

      const componentHistory: IComponentHistoryValue[] = [];

      let count = value.count;
      let prevDate = value.date;

      const history = value.history.sort((n1, n2) => DateTime.fromISO(n1.date).diff(DateTime.fromISO(n2.date)).as('seconds'));

      for (let i = 0; i < history.length; i++) {
        const cur = history[i];
        const diff = i > 0 ? cur.count - count : undefined;
        if (cur.count !== undefined) {
          count = cur.count;
        }

        const historyValue: IComponentHistoryValue = {
          date: DateTime.fromISO(cur.date),
          count: cur.count,
          diff: diff,
          period: diff ? diff / DateTime.fromISO(cur.date).diff(DateTime.fromISO(prevDate))
            .as('days') : undefined
        };
        prevDate = cur.date;
        componentHistory.push(historyValue);
      }
      updatedComponentValue.history = componentHistory.reverse();
    }


    if (value != null && value.date != null && value.date.length > 0) {
      updatedComponentValue.isoDate = value.date;
      updatedComponentValue.dateString = DateTime.fromISO(value.date).toFormat('DD');
    } else {
      const now = Utilities.now();
      updatedComponentValue.isoDate = now.toISO();
      updatedComponentValue.dateString = now.toFormat('DD');
    }

    if (value != null && value.count != null) {
      updatedComponentValue.count = value.count;
      if (this.buildDifference && lastCount !== undefined) {
        updatedComponentValue.diff = value.count - lastCount;
      }
    }

    if (this.buildDifference && lastDate !== undefined) {
      const curDate: DateTime = DateTime.fromISO(updatedComponentValue.isoDate);
      updatedComponentValue.period = updatedComponentValue.diff / new RoundPipe().transform(curDate.diff(lastDate).as('days'));
    }

    this.curValue = updatedComponentValue;
  }
}
