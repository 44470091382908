import {Injectable} from '@angular/core';
import {NavigationService} from '../navigation.service';
import {OperatorService} from '../../services/operator-service';
import {MenuItem} from 'primeng/api';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {OPERATOR_ID_ROUTE_PARAM, PROTOCOL_ID_ROUTE_PARAM, STRUCTURE_ID_ROUTE_PARAM} from '../../app/route-parameters';
import {StructureService} from '../../services/strcuture-service';
import {ProtocolService} from '../../services/protocol.service';

@Injectable()
export class NavOperatorStructureProtocolsDetailGuard implements Resolve<MenuItem[]> {

  constructor(private navigationService: NavigationService,
              private operatorService: OperatorService,
              private structureService: StructureService,
              private protocolService: ProtocolService) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MenuItem[]> | Promise<MenuItem[]> | MenuItem[] {
    const operatorId = route.paramMap.get(OPERATOR_ID_ROUTE_PARAM);
    const structureId = route.paramMap.get(STRUCTURE_ID_ROUTE_PARAM);
    const protocolId = route.paramMap.get(PROTOCOL_ID_ROUTE_PARAM);

    const operator$ = this.operatorService.load(operatorId);
    const structure$ = this.structureService.getById(structureId);
    const protocol$ = this.protocolService.load(protocolId, structureId);

    return Observable.zip(operator$, structure$, protocol$, (aOperator, aStructure, aProtocol) => {
      const items: MenuItem[] = [
        {label: 'Betreiber', routerLink: ['/operators']},
        {label: aOperator.name, routerLink: ['/operators', aOperator.dbId]},
        {label: aStructure.name, routerLink: ['/operators', aOperator.dbId, 'structures', aStructure.dbId]},
        {
          label: aProtocol.createdDate.toFormat('DD HH:mm'),
          routerLink: ['/operators', aOperator.dbId, 'structures', aStructure.dbId, 'protocols', aProtocol.dbId]
        },
      ];
      return items;
    }).do(aItems => {
      this.navigationService.clearItems();
      this.navigationService.addItems(...aItems);
    });
  }
}
