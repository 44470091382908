import {AfterViewInit, Component, OnInit} from '@angular/core';
import {JsonStructure} from 'rueb-data-model/src/json-structure';
import {JsonForm, JsonProtocol, PlaceholderSections, Utilities} from 'rueb-data-model';
import {RestProvider} from '../../providers/rest/rest.provider';
import {ActivatedRoute, Router} from '@angular/router';
import {Data} from '../../providers/data/data.provider';
import {OperatorService} from '../../services/operator-service';
import {StructureService} from '../../services/strcuture-service';
import {JsonStructureInfo} from 'rueb-data-model/src/json-structure-info';
import {JsonFileData} from 'rueb-data-model/src/json-file-data';
import {JsonStructureInfoDocumentSection} from 'rueb-data-model/src/json-structure-info-document-section';
import {FormService} from '../../services/form.service';
import {SuiModalService} from 'ng2-semantic-ui';
import {AlertModal} from '../../modals/confirm-modal';
import {
  FormSelectionOptionVM,
  OperatorSelectOptionVM,
  SelectFormModal,
  SelectFormModalResult,
  StructureSelectOptionVM
} from './select-form-modal';
import {StructInfoDocSectionVM} from '../../components/struct-info-doc-section/struct-info-doc-section.component';
import {Session} from '../../providers/session/session';
import {ProtocolService} from '../../services/protocol.service';
import {JsonDueDateInfo} from 'rueb-data-model/src/json-due-date-info';
import {FileDataService} from '../../services/file-data.service';
import {STRUCTURE_ID_ROUTE_PARAM} from '../../app/route-parameters';

class ProtocolVM {
  protocol: JsonProtocol;
  createdDateString: string;
  pictureAvailable: boolean;
}

@Component({
  selector: 'app-struct-details',
  templateUrl: 'struct-details.component.html',
  styleUrls: ['struct-details.component.css']
})
export class StructDetailsComponent implements OnInit, AfterViewInit {
  mode: string;
  id: string;
  public structure: JsonStructure;
  protocols: Array<JsonProtocol> = [];
  protocolVMs: ProtocolVM[] = [];
  items: Array<JsonForm> = [];
  sections: Array<StructInfoDocSectionVM> = [];

  dataAvailable = {structure: false, protocols: false, forms: false, sections: false, protocolNotes: false, protocolVMs: false};
  ready = false;

  canEdit = false;

  sectionFormLabel: string;

  tabsActive = {
    protocols: false,
    forms: false,
    infos: false
  };

  constructor(public structService: StructureService,
              private restProvider: RestProvider,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private data: Data,
              private operatorService: OperatorService,
              private formService: FormService,
              private protocolService: ProtocolService,
              private fileDataSerice: FileDataService,
              private modalService: SuiModalService,
              private session: Session) {
  }

  ngOnInit(): void {
    this.canEdit = this.session.isAdmin;
    const activatedRoute = this.activatedRoute.snapshot;
    if (activatedRoute.data.create) {
      this.structure = JsonStructure.empty();
      this.setDefaultSections(this.structure);
      this.structure.operatorId = this.data.storage.operatorId;
      Utilities.ensureDbChannel(this.structure, this.data.storage.operatorId);
      this.mode = 'edit';
      this.ready = true;
    } else {
      this.mode = 'saved';
      this.id = activatedRoute.params[STRUCTURE_ID_ROUTE_PARAM];
      this.refresh();
    }
  }

  ngAfterViewInit(): void {
    this.activatedRoute.paramMap.subscribe(aParams => { // must be called after view init to avoid tab flickering
      const openTab: string = aParams.get('openTab');
      if (this.tabsActive.hasOwnProperty(openTab)) {
        setTimeout(() => { // need additional timeout to avoid tab flickering
          this.tabsActive[openTab] = true;
        });
      }
    });
  }

  showDetails(item: JsonForm) {
    this.router.navigate(['./forms', item.dbId], {relativeTo: this.activatedRoute});
  }

  createForm(aTemplateForm?: JsonForm) {
    this.data.storage = {templateForm: aTemplateForm};
    this.router.navigate(['./forms', 'create'], {relativeTo: this.activatedRoute});
  }

  refresh() {
    this.dataAvailable.structure = false;
    this.dataAvailable.protocols = false;
    this.dataAvailable.protocolNotes = false;
    this.dataAvailable.protocolVMs = false;
    this.dataAvailable.forms = false;
    this.dataAvailable.sections = false;
    this.structService.getById(this.id).subscribe(structure => {
      this.structure = structure;
      this.dataAvailable.structure = true;
      this.dataLoaded();
    });
    this.structService.getProtocols(this.id).subscribe(protocols => {
      this.protocols = protocols.sort((a, b) => +b.createdDate - +a.createdDate);
      this.dataAvailable.protocolNotes = true;
      this.dataAvailable.protocols = true;
      this.dataLoaded();
    });
    this.structService.getForms(this.id).subscribe(forms => {
      this.items = forms;
      this.dataAvailable.forms = true;
      this.dataLoaded();
    });
  }

  getFormLabel(protocol: JsonProtocol): string {
    for (const form of this.items) {
      if (form.dbId === protocol.formId) {
        return form.name;
      }
    }
    return protocol.formName;
  }

  submitForm() {
    console.log(this.structure.operatorId);
    this.structService.put(this.structure).subscribe(aResult => {
      this.mode = 'saved';
      if (this.mode === 'edit') {
        this.router.navigate(['../', aResult.dbId], {relativeTo: this.activatedRoute})
          .catch(err => console.error('Could not navigate to structure detail', err));
      }
    });
  }

  deleteForm(aForm: JsonForm) {
    this.modalService.open(new AlertModal('Formular löschen', `Formular ${aForm.name} wird gelöscht!`)).onApprove(() => {
      this.formService.delete(aForm).subscribe(() => {
        this.modalService.open(new AlertModal('Löschen erfolgreich', 'Formular wurde erfolgreich gelöscht'));
      }, error => {
        console.error(error);
        this.modalService.open(new AlertModal('Löschen fehlgeschlagen', 'Formular konnte nicht gelöscht werden'));
      }, () => {
        this.refresh();
      });
    });
  }

  infoAdded(infoAddedEvent: { section: JsonStructureInfoDocumentSection, info: JsonStructureInfo, fileData: JsonFileData }) {
    Utilities.ensureDbChannel(infoAddedEvent.fileData, this.structure.operatorId);
    this.fileDataSerice.saveStructureInfo(infoAddedEvent.fileData, this.structure).toPromise()
      .then(aSavedFileData => {
        infoAddedEvent.info.dataId = aSavedFileData.dbId;
        return this.saveStructure();
      })
      .then(() => {
        this.refresh();
      })
      .catch(error => {
        console.error('Error adding structure info: ', error);
        console.error(error);
        if (error['status'] === 413) {
          this.modalService.open(new AlertModal('Speichern fehlgeschlagen',
            'Die von Ihnen gewählte Datei ist zu groß. Bitte versuchen Sie die Datei zu komprimieren'));
        } else {
          this.modalService.open(new AlertModal('Speichern fehlgeschlagen',
            'Die von Ihnen gewählte Datei konnte nicht gespeichert werden.'));
        }
        Utilities.removeElementFromArray(infoAddedEvent.section.infos, infoAddedEvent.info);
        if (infoAddedEvent.info.dataId) {
          this.restProvider.deleteRemoteData(infoAddedEvent.info.dataId).subscribe(() => {
            console.info('Reverted previously saved file data');
          }, deleteError => {
            console.error('Could not revert previously saved file data');
            console.error(deleteError);
          });
        }
        return this.saveStructure().then(() => this.refresh());
      }).catch(error => {
      console.error('Error reverting operator');
      console.error(error);
    });
  }

  infoRemoved(infoRemovedEvent: { section: JsonStructureInfoDocumentSection, info: JsonStructureInfo }) {
    this.restProvider.deleteRemoteData(infoRemovedEvent.info.dataId).toPromise().then(() => {
      return this.saveStructure();
    }).then(() => {
      this.refresh();
    });
  }

  sectionAdded() {
    this.saveStructure().then(() => {
      this.refresh();
    });
  }

  sectionRemoved(sectionRemovedEvent: { section: JsonStructureInfoDocumentSection, subSection: JsonStructureInfoDocumentSection }) {
    let sectionsToClear: JsonStructureInfoDocumentSection[] = [sectionRemovedEvent.subSection];
    let filesToRemove: string[] = [];
    while (sectionsToClear.length > 0) {
      const curSection = sectionsToClear.pop();
      filesToRemove = filesToRemove.concat(curSection.infos.map(curInfo => curInfo.dataId).filter(curDataId => curDataId));
      sectionsToClear = sectionsToClear.concat(curSection.subSections);
    }
    Promise.all(filesToRemove.map(curDataId => this.restProvider.deleteRemoteData(curDataId).toPromise()))
      .then(() => {
        return this.saveStructure();
      })
      .then(() => {
        this.refresh();
      });
  }

  handleRemoveSectionRequest(sectionToRemove: JsonStructureInfoDocumentSection) {
    this.modalService.open(new AlertModal('Löschen', `Möchten Sie den Abschnitt ${sectionToRemove.label} wirklich löschen?`))
      .onApprove(() => {
        Utilities.removeElementFromArray(this.structure.infoDocument.sections, sectionToRemove);
        this.sectionRemoved({section: null, subSection: sectionToRemove});
      });
  }

  addSection(value: {}) {
    if (this.sectionFormLabel) {
      console.log(value);
      this.structure.infoDocument.sections.push(JsonStructureInfoDocumentSection.create(this.sectionFormLabel));
      this.sectionFormLabel = undefined;
      this.saveStructure().then(() => {
        this.refresh();
      });
    }
  }

  saveStructure(): Promise<Object> {
    console.debug(this.structure);
    return this.structService.put(this.structure).toPromise();
  }

  importForm() {
    this.operatorService.getAll().toPromise().then(operators => {
      return this.operatorService.getStructuresGrouped(operators.map(curOp => curOp.dbId)).toPromise().then(structureMap => {
        const allStructures: JsonStructure[] = Utilities.flatten(Array.from(structureMap.values()));
        return this.structService.getFormsGrouped(allStructures.map(curStruct => curStruct.dbId), false).toPromise().then(formMap => {
          let selectOptions: OperatorSelectOptionVM[] = [];
          for (const curOperator of operators) {
            const curSelectOptions: OperatorSelectOptionVM[] = [];
            const operatorSelectOption = new OperatorSelectOptionVM();
            operatorSelectOption.operator = curOperator;
            operatorSelectOption.structures = [];
            curSelectOptions.push(operatorSelectOption);
            const structures = structureMap.get(curOperator.dbId);
            if (structures) {
              structures.sort((a, b) => a.name.localeCompare(b.name));
              for (const curStructure of structures) {
                const structureSelectOption = new StructureSelectOptionVM();
                structureSelectOption.structure = curStructure;
                structureSelectOption.forms = [];
                operatorSelectOption.structures.push(structureSelectOption);
                const forms = formMap.get(curStructure.dbId);
                if (forms) {
                  forms.sort((a, b) => a.name.localeCompare(b.name));
                  for (const curForm of forms) {
                    const formSelectOption = new FormSelectionOptionVM();
                    formSelectOption.form = curForm;
                    structureSelectOption.forms.push(formSelectOption);
                  }
                }
              }
            }
            selectOptions = selectOptions.concat(curSelectOptions);
          }
          return selectOptions;
        });
      });
    }).then(selectOptions => {
      this.modalService.open(new SelectFormModal(selectOptions)).onApprove((result: SelectFormModalResult) => {
        const copiedForm: JsonForm = Utilities.fromJson(Utilities.toJson(result.selectedForm, JsonForm), JsonForm);
        copiedForm.dbId = Utilities.getUUID();
        copiedForm.dbRev = undefined;
        copiedForm.dbChannels = [this.structure.operatorId];
        copiedForm.structureId = this.structure.dbId;
        const dueDates: Map<string, JsonDueDateInfo> = new Map<string, JsonDueDateInfo>();
        Utilities.flattenForm(copiedForm).forEach(aFormItem => {
          aFormItem.id = Utilities.getTaskId(copiedForm.dbId);
          const dueDate: JsonDueDateInfo = JsonDueDateInfo.create(aFormItem.id, null, undefined, undefined);
          dueDates.set(dueDate.taskId, dueDate);
        });
        this.createForm(copiedForm);
      });
    });
  }

  private setDefaultSections(aStructure: JsonStructure) {
    const picturesSection = JsonStructureInfoDocumentSection.create('Bilder');
    const picturesFromProtocolsSection = JsonStructureInfoDocumentSection.create('Bilder aus Protokollen');
    picturesFromProtocolsSection.placeholderFor = PlaceholderSections.PROTOCOL_PICTURES;
    picturesSection.subSections = [picturesFromProtocolsSection];
    aStructure.infoDocument.sections = [
      JsonStructureInfoDocumentSection.create('Pläne'),
      picturesSection,
      JsonStructureInfoDocumentSection.create('Betriebsanweisungen'),
      JsonStructureInfoDocumentSection.create('Berichte Datenauswertungen (Probebetrieb)'),
      JsonStructureInfoDocumentSection.create('Drosselprüfberichte'),
    ];
  }

  private dataLoaded() {
    if (!this.dataAvailable.sections && this.dataAvailable.structure && this.dataAvailable.protocols && this.dataAvailable.protocolNotes) {
      this.sections = StructInfoDocSectionVM.convertSectionsToVM(this.structure.infoDocument.sections, this.protocols);
      this.dataAvailable.sections = true;
    }

    if (!this.dataAvailable.protocolVMs && this.dataAvailable.protocols && this.dataAvailable.protocolNotes) {
      this.protocolVMs = this.convertProtocolsToVMs();
      this.dataAvailable.protocolVMs = true;
    }

    let ready = true;
    for (const dataAvailableKey in this.dataAvailable) {
      if (!dataAvailableKey) {
        ready = false;
      }
    }
    this.ready = ready;
  }

  private convertProtocolsToVMs(): ProtocolVM[] {
    const protocolVMs: ProtocolVM[] = [];
    for (const curProtocol of this.protocols) {
      const curProtocolVM: ProtocolVM = new ProtocolVM();
      curProtocolVM.protocol = curProtocol;
      curProtocolVM.createdDateString = curProtocol.createdDate.toFormat('DD HH:mm');
      protocolVMs.push(curProtocolVM);
      for (const curProtocolItem of curProtocol.values) {
        const curProtocolNote = curProtocolItem.note;
        if (curProtocolNote && curProtocolNote.pictures && curProtocolNote.pictures.length > 0) {
          curProtocolVM.pictureAvailable = true;
        }
      }
    }
    return protocolVMs;
  }
}
