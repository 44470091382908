import {Injectable} from '@angular/core';
import {JsonStructure} from 'rueb-data-model';
import {StructureService} from '../../../services/strcuture-service';

@Injectable()
export class AvailableStructuresService {
  constructor(private structureService: StructureService) {
    structureService.getAll().subscribe({
      next: aStructures => this._allStructures = aStructures,
      error: err => console.error('Could not load all structures', err),
    });
  }

  private _allStructures: JsonStructure[] = [];

  get allStructures(): JsonStructure[] {
    return this._allStructures;
  }

  searchStructures(aNameQuery: string): JsonStructure[] {
    return this._allStructures.filter(aStructure => aStructure.name.toLowerCase().includes(aNameQuery.toLowerCase()))
      .sort((a, b) => a.name.localeCompare(b.name));
  }


}
