import {Injectable} from '@angular/core';
import {NavigationService} from '../navigation.service';
import {OperatorService} from '../../services/operator-service';
import {MenuItem} from 'primeng/api';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {OPERATOR_ID_ROUTE_PARAM} from '../../app/route-parameters';

@Injectable()
export class NavOperatorStructureCreateGuard implements Resolve<MenuItem[]> {

  constructor(private navigationService: NavigationService, private operatorService: OperatorService) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MenuItem[]> | Promise<MenuItem[]> | MenuItem[] {
    const operatorId = route.paramMap.get(OPERATOR_ID_ROUTE_PARAM);

    return this.operatorService.load(operatorId).map(aOperator => {
      const items: MenuItem[] = [
        {label: 'Betreiber', routerLink: ['/operators']},
        {label: aOperator.name, routerLink: ['/operators', aOperator.dbId]},
        {label: 'Neues Bauwerk'}
      ];
      return items;
    }).do(aItems => {
      this.navigationService.clearItems();
      this.navigationService.addItems(...aItems);
    });
  }
}
