import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {SuiModule} from 'ng2-semantic-ui';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {OperatorDetailsComponent} from './operator-details.component';
import {OperatorListComponent} from './operator-list.component';
import {StructureCreateModalComponent} from './structure-create-modal/structure-create-modal.component';
import {CommonComponentsModule} from '../../components/common-components.module';

@NgModule({
  declarations: [
    OperatorDetailsComponent,
    OperatorListComponent,
    StructureCreateModalComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    SuiModule,
    BrowserAnimationsModule,
    CommonComponentsModule,
  ],
  entryComponents: [
    StructureCreateModalComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class OperatorsModule {
}
