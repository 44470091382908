import {Component} from '@angular/core';

@Component({
  selector: 'app-default-header',
  templateUrl: 'default-header.component.html',
  styleUrls: ['default-header.component.css']
})
export class DefaultHeaderComponent {

}
