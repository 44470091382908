import {Component, forwardRef, Input, OnChanges} from '@angular/core';
import {DateTime} from 'luxon';
import {ValueAccessorBase} from '../ValueAccessorBase';
import {RuebInterval, Utilities} from 'rueb-data-model';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

/**
 * Generated class for the FormCheckboxComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'form-checkbox-semantic',
  templateUrl: 'form-checkbox-semantic.html',
  styleUrls: ['form-checkbox-semantic.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormCheckboxSemanticComponent),
    multi: true,
  }],
})
export class FormCheckboxSemanticComponent extends ValueAccessorBase<boolean> implements OnChanges {
  @Input() itemLabel: string;
  @Input() enReadonly: boolean;
  @Input() dueDate: DateTime;
  @Input() taskInterval: RuebInterval;

  dueDateString: string;
  dueDateDue: boolean;

  constructor() {
    super();
  }

  ngOnChanges(): void {
    this.dueDateDue = Utilities.isDueDateDue(this.dueDate);
    this.dueDateString = this.dueDate ? this.dueDate.toFormat('DD') : '';
  }

  changed() {
    this.labelChanged.emit(this.itemLabel);
  }

}
