import {Injectable} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {Resolve} from '@angular/router/src/interfaces';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {NavigationService} from '../navigation.service';

@Injectable()
export class NavClearGuard implements Resolve<MenuItem[]> {

  constructor(private navigationService: NavigationService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MenuItem[]> | Promise<MenuItem[]> | MenuItem[] {
    this.navigationService.clearItems();
    return [];
  }
}
