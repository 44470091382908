import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {ShowProtocolComponent} from './show-protocol';
import {FormComponentsModule} from '../../forms-components/form-components.module';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [
    ShowProtocolComponent,
  ],
  imports: [
    CommonModule,
    FormComponentsModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class ShowProtocolPageModule {
}
