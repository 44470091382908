import {ComponentModalConfig, ModalSize} from 'ng2-semantic-ui';
import {AlertModalComponent, IAlertModalContext} from './alert-modal.component';

export class AlertModal extends ComponentModalConfig<IAlertModalContext, void, void> {
  constructor(title: string, message: string, size = ModalSize.Small) {
    super(AlertModalComponent, {title, message});

    this.isClosable = false;
    this.transitionDuration = 200;
    this.size = size;
  }
}
