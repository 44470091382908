import {Injectable} from '@angular/core';
import {MenuItem} from 'primeng/api';

@Injectable()
export class NavigationService {
  private _items: MenuItem[] = [
    {label: 'Test 1'},
    {label: 'Test 2'},
    {label: 'Betreiber', routerLink: ['/operators']}
  ];


  get items(): MenuItem[] {
    return this._items;
  }

  public clearItems() {
    this._items = [];
  }

  public addItem(aItem: MenuItem) {
    this._items.push(aItem);
  }

  public addItems(...aItems: MenuItem[]) {
    this._items.push(...aItems);
  }
}
