import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {JsonStructure, JsonTimetrackingStructure, JsonTimetrackingTask, JsonTimetrackingUser, JsonUser} from 'rueb-data-model';
import {DateTime} from 'luxon';
import {PRIME_NG_CALENDAR_GERMAN_LOCALE} from '../../../utilities/prime-ng-calendar-locale';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';

const CONTROL_START = 'start';
const CONTROL_END = 'end';
const CONTROL_USER = 'user';
const CONTROL_STRUCTURES = 'structures';

interface EditFormValue {
  start: Date;
  end: Date;
  user: JsonTimetrackingUser;
  structures: JsonTimetrackingStructure[];
}

@Component({
  selector: 'app-timetracking-task-edit-dialog',
  templateUrl: 'timetracking-task-edit-dialog.component.html',
  styleUrls: ['timetracking-task-edit-dialog.component.css']
})
export class TimetrackingTaskEditDialogComponent implements OnInit, OnDestroy {
  @Output()
  edited = new EventEmitter<JsonTimetrackingTask>();
  editTaskForm: FormGroup;
  display = false;

  timetrackingTask: JsonTimetrackingTask;

  availableUsers: JsonUser[];

  availableStructure: JsonStructure[];
  allUsers: JsonTimetrackingUser[] = [];
  allStructures: JsonTimetrackingStructure[] = [];

  selectedStructures: JsonTimetrackingStructure[] = [];
  de = PRIME_NG_CALENDAR_GERMAN_LOCALE;

  private destroySubject = new Subject();

  constructor(private fb: FormBuilder) {
  }

  get structuresForm(): any {
    return this.editTaskForm['structures'];
  }

  ngOnInit(): void {
    this.editTaskForm = this.fb.group({
      [CONTROL_START]: new FormControl(null, Validators.required),
      [CONTROL_END]: new FormControl(null, Validators.required),
      [CONTROL_USER]: new FormControl(null, Validators.required),
      [CONTROL_STRUCTURES]: new FormControl([], Validators.required),
    }, {validator: this.dateLessThan('start', 'end')});

    this.editTaskForm.get(CONTROL_STRUCTURES).valueChanges.takeUntil(this.destroySubject).subscribe(
      {
        next: aSelectedStructures => this.selectedStructures = aSelectedStructures,
      }
    );
  }

  dateLessThan(aStartTime: string, aEndTime: string) {
    return (group: FormGroup): { [key: string]: any } => {
      const start = group.controls[aStartTime];
      const end = group.controls[aEndTime];
      if (start.value > end.value) {
        return {
          dates: 'Die eingegebene Startzeit muss vor der Endzeit liegen.'
        };
      }
      return {};
    };
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  public showWith(aTimetrackingTask: JsonTimetrackingTask, aAvailableUsers: JsonUser[], aAvailableStructure: JsonStructure[]) {
    this.display = true;

    this.availableUsers = aAvailableUsers;

    this.availableStructure = aAvailableStructure;
    this.timetrackingTask = aTimetrackingTask;

    // add all users to JsonTimetrackingUser list
    for (const availableUser of aAvailableUsers) {
      const newUser = new JsonTimetrackingUser();
      newUser.id = availableUser.dbId;
      newUser.name = availableUser.name;
      this.allUsers.push(newUser);

    }
    this.allUsers.sort((a, b) => a.name.localeCompare(b.name));

    // add all structures to JsonTimetrackingStructure list
    for (const structure of this.availableStructure) {
      const newStructure = new JsonTimetrackingStructure();
      newStructure.id = structure.dbId;
      newStructure.name = structure.name;
      this.allStructures.push(newStructure);
    }
    this.allStructures.sort((a, b) => a.name.localeCompare(b.name));

    const selectedStart = aTimetrackingTask.start.toJSDate();
    const selectedEnd = aTimetrackingTask.end.toJSDate();
    const selectedUser = aTimetrackingTask.user;
    const selectedStructures = aTimetrackingTask.structures.slice();

    const editFormValue: EditFormValue = {
      start: selectedStart,
      end: selectedEnd,
      user: selectedUser,
      structures: selectedStructures,
    };
    this.editTaskForm.setValue(editFormValue);
  }

  confirmTaskChange() {
    console.log('confirmTaskChange');

    const curValue = this.editTaskForm.value as EditFormValue;
    this.timetrackingTask.user = curValue.user;
    this.timetrackingTask.start = DateTime.fromJSDate(curValue.start).set({second: 0});
    this.timetrackingTask.end = DateTime.fromJSDate(curValue.end).set({second: 0});
    this.timetrackingTask.structures = curValue.structures;

    this.edited.emit(this.timetrackingTask);

    this.resetDialog();
  }

  resetDialog() {
    this.display = false;

    this.timetrackingTask = undefined;
    this.availableUsers = undefined;
    this.availableStructure = undefined;
    this.allUsers = [];
    this.allStructures = [];
    this.selectedStructures = [];
    const resetValue: EditFormValue = {
      start: null,
      end: null,
      user: null,
      structures: [],
    };
    this.editTaskForm.reset(resetValue);
  }
}
