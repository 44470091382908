import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OperatorService} from '../../services/operator-service';
import {
  Features,
  JsonFileData,
  JsonOperator,
  JsonOperatorSystemPlan,
  JsonStructure,
  JsonStructureInfo,
  JsonStructureInfoDocumentSection,
  Utilities
} from 'rueb-data-model';
import {Data} from '../../providers/data/data.provider';
import {StructureService} from '../../services/strcuture-service';
import {FormService} from '../../services/form.service';
import {StructInfoDocSectionVM} from '../../components/struct-info-doc-section/struct-info-doc-section.component';
import {RestProvider} from '../../providers/rest/rest.provider';
import {SuiModalService} from 'ng2-semantic-ui';
import {FileData, FileDataService} from '../../services/file-data.service';
import {AlertModal} from '../../modals/confirm-modal';
import {StructureCreateModal, StructureCreateModalResult} from './structure-create-modal/structure-create-modal.component';
import {PictureModal} from '../../modals/pictureModal/picture-modal.component';
import {OPERATOR_ID_ROUTE_PARAM} from '../../app/route-parameters';
import {FeaturesService} from '../../providers/features/features.service';

@Component({
  selector: 'app-operator-details',
  templateUrl: 'operator-details.html',
  styleUrls: ['operator-detail.css']
})
export class OperatorDetailsComponent implements OnInit, AfterViewInit {
  operator: JsonOperator;
  operatorLogo: FileData;
  operatorSystemPlan: FileData;
  structures: Array<JsonStructure>;
  sections: Array<StructInfoDocSectionVM> = [];

  canEdit = true;
  editingOperator = false;

  sectionFormLabel: string;

  editingLogo = false;
  editingSystemPlan = false;

  timetrackingEnabled = false;

  tabsActive = {
    structs: false,
    infos: false,
    other: false
  };

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private operatorService: OperatorService,
              private structureService: StructureService,
              private formService: FormService,
              private data: Data,
              private restProvider: RestProvider,
              private fileDataService: FileDataService,
              private modalService: SuiModalService,
              private featureService: FeaturesService) {
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(aData => {
      if (aData.create) {
        this.editingOperator = true;
        this.createOperator();
      } else {
        this.editingOperator = false;
        this.refresh();
      }
    });
  }

  ngAfterViewInit(): void {
    this.activatedRoute.paramMap.subscribe(aParams => {
      const openTab: string = aParams.get('openTab');
      if (this.tabsActive.hasOwnProperty(openTab)) {
        setTimeout(() => { // need additional timeout to avoid tab flickering
          this.tabsActive[openTab] = true;
        });
      }
    });
  }

  addStructure() {
    this.modalService.open(new StructureCreateModal(this.operator)).onApprove((aResult: StructureCreateModalResult) => {
      this.structureService.put(aResult.structure).subscribe({
        next: aSavedStructure => {
          this.router.navigate(['./structures', aSavedStructure.dbId], {relativeTo: this.activatedRoute});
        },
        error: err => {
          console.error('Error saving structure', err);
          this.modalService.open(new AlertModal('Fehler beim speichern', `Bauwerk ${aResult.structure} konnte nicht gespeichert werden`));
        }
      });
    });
  }

  deleteStructure(aStructure: JsonStructure) {
    this.modalService.open(new AlertModal('Löschen', `Möchten Sie das Bauwerk ${aStructure.name} wirklich löschen?`)).onApprove(() => {
      this.structureService.delete(aStructure).subscribe(
        val => {
          console.log('DELETE call successful value returned in body', val);
        },
        response => {
          console.log('DELETE call in error', response);
        },
        () => {
          console.log('The DELETE observable is now completed.');
          this.ngOnInit();
        }
      );
    });
  }

  addSection(value: {}) {
    if (this.sectionFormLabel) {
      console.log(value);
      this.operator.infoDoc.sections.push(JsonStructureInfoDocumentSection.create(this.sectionFormLabel));
      this.sectionFormLabel = undefined;
      this.saveOperator().then(() => {
        this.refresh();
      });
    }
  }

  infoAdded(infoAddedEvent: { section: JsonStructureInfoDocumentSection, info: JsonStructureInfo, fileData: JsonFileData }) {
    Utilities.ensureDbChannel(infoAddedEvent.fileData, this.operator.dbId);
    this.fileDataService.saveOperatorInfo(infoAddedEvent.fileData, this.operator).toPromise()
      .then(aSavedFileData => {
        infoAddedEvent.info.dataId = aSavedFileData.dbId;
        return this.saveOperator();
      })
      .then(() => {
        this.refresh();
      })
      .catch(error => {
        console.error('Error adding operator info: ', error);
        console.error(error);
        if (error['status'] === 413) {
          this.modalService.open(new AlertModal('Speichern fehlgeschlagen',
            'Die von Ihnen gewählte Datei ist zu groß. Bitte versuchen Sie die Datei zu komprimieren'));
        } else {
          this.modalService.open(new AlertModal('Speichern fehlgeschlagen',
            'Die von Ihnen gewählte Datei konnte nicht gespeichert werden.'));
        }
        Utilities.removeElementFromArray(infoAddedEvent.section.infos, infoAddedEvent.info);
        if (infoAddedEvent.info.dataId) {
          this.restProvider.deleteRemoteData(infoAddedEvent.info.dataId).subscribe(() => {
            console.info('Reverted previously saved file data');
          }, deleteError => {
            console.error('Could not revert previously saved file data');
            console.error(deleteError);
          });
        }
        return this.saveOperator().then(() => this.refresh());
      }).catch(error => {
      console.error('Error reverting operator');
      console.error(error);
    });
  }

  infoRemoved(infoRemovedEvent: { section: JsonStructureInfoDocumentSection, info: JsonStructureInfo }) {
    this.restProvider.deleteRemoteData(infoRemovedEvent.info.dataId).toPromise().then(() => {
      return this.saveOperator();
    }).then(() => {
      this.refresh();
    });
  }

  sectionAdded() {
    this.saveOperator().then(() => {
      this.refresh();
    });
  }

  sectionRemoved(sectionRemovedEvent: { section: JsonStructureInfoDocumentSection, subSection: JsonStructureInfoDocumentSection }) {
    let sectionsToClear: JsonStructureInfoDocumentSection[] = [sectionRemovedEvent.subSection];
    let filesToRemove: string[] = [];
    while (sectionsToClear.length > 0) {
      const curSection = sectionsToClear.pop();
      filesToRemove = filesToRemove.concat(curSection.infos.map(curInfo => curInfo.dataId).filter(curDataId => curDataId));
      sectionsToClear = sectionsToClear.concat(curSection.subSections);
    }
    Promise.all(filesToRemove.map(curDataId => this.restProvider.deleteRemoteData(curDataId).toPromise()))
      .then(() => {
        return this.saveOperator();
      })
      .then(() => {
        this.refresh();
      });
  }

  handleRemoveSectionRequest(sectionToRemove: JsonStructureInfoDocumentSection) {
    this.modalService.open(new AlertModal('Löschen', `Möchten Sie den Abschnitt ${sectionToRemove.label} wirklich löschen?`))
      .onApprove(() => {
        Utilities.removeElementFromArray(this.operator.infoDoc.sections, sectionToRemove);
        this.sectionRemoved({section: null, subSection: sectionToRemove});
      });
  }

  updateLogo(fileInput?: HTMLInputElement) {
    if (fileInput) {
      if (fileInput.files != null && fileInput.files.length > 0) {
        const selectedFile: File = fileInput.files.item(0);
        console.log(selectedFile.type);
        if (selectedFile.type === 'application/pdf' || selectedFile.type.startsWith('image')) {
          const fileReader: FileReader = new FileReader();
          fileReader.onload = ev => {
            const result = ev.target['result'];
            if (result) {
              const fileData: JsonFileData = JsonFileData.create(result, selectedFile.type);
              this.fileDataService.saveOperatorLogo(fileData, this.operator).subscribe(savedFileData => {
                this.operator.logoId = savedFileData.dbId;
                this.toggleEditingLogo();
                this.saveOperator();
              });
            }
          };
          fileReader.readAsDataURL(selectedFile);
        } else {
          console.error('You must select an image or pdf');
        }
      } else {
        console.error('You must select a file');
      }
    } else {
      this.modalService.open(new AlertModal('Löschen', 'Soll das Betreiberlogo wirklich gelöscht werden?')).onApprove(() => {
        this.restProvider.deleteRemoteData(this.operator.logoId).subscribe(() => {
          this.operator.logoId = undefined;
          this.editingLogo = false;
          this.saveOperator();
        });
      });
    }
  }

  updateSystemPlan(fileInput?: HTMLInputElement) {
    if (fileInput) {
      if (fileInput.files != null && fileInput.files.length > 0) {
        const selectedFile: File = fileInput.files.item(0);
        console.log(selectedFile.type);
        if (selectedFile.type === 'application/pdf' || selectedFile.type.startsWith('image')) {
          const fileReader: FileReader = new FileReader();
          fileReader.onload = ev => {
            const result = ev.target['result'];
            if (result) {
              const fileData: JsonFileData = JsonFileData.create(result, selectedFile.type);
              this.fileDataService.saveOperatorSystemPlan(fileData, this.operator).subscribe(savedFileData => {
                this.operator.systemPlan = JsonOperatorSystemPlan.create(savedFileData.dbId);
                this.toggleEditingSystemPlan();
                this.saveOperator();
              });
            }
          };
          fileReader.readAsDataURL(selectedFile);
        } else {
          console.error('You must select an image or pdf');
        }
      } else {
        console.error('You must select a file');
      }
    } else {
      this.modalService.open(new AlertModal('Löschen', 'Möchten Sie den Systemplan wirklich löschen?')).onApprove(() => {
        this.restProvider.deleteRemoteData(this.operator.systemPlan.pictureId).subscribe(() => {
          this.operator.systemPlan = undefined;
          this.editingSystemPlan = false;
          this.saveOperator();
        });
      });
    }
  }

  showPicture(aPictureId: string, aFileName: string): void {
    if (aPictureId) {
      this.fileDataService.get(aPictureId).subscribe(aFileData => {
        this.modalService.open(new PictureModal(aFileData, {fileName: aFileName}));
      });
    }
  }

  toggleEditingLogo() {
    this.editingLogo = !this.editingLogo;
  }

  toggleEditingSystemPlan() {
    this.editingSystemPlan = !this.editingSystemPlan;
  }

  toggleBetriebstagebuchAvailable() {
    this.operator.betriebstagebuchAvailable = !this.operator.betriebstagebuchAvailable;
    this.saveOperator();
  }

  toggleTimeTrackingAvailable() {
    this.operator.timeTrackingAvailable = !this.operator.timeTrackingAvailable;
    this.saveOperator();
  }

  private saveOperator(): Promise<Object> {
    console.debug(this.operator);
    return this.operatorService.put(this.operator).do({
      next: aResult => {
        if (this.editingOperator) {
          this.editingOperator = false;
          this.router.navigate(['../', aResult._id], {relativeTo: this.activatedRoute})
            .catch(err => console.error('Could not navigate to operator details', err));
        }
      }
    }).toPromise();
  }

  private refresh(): void {
    this.featureService.featureAvailable(Features.TIME_TRACKING).subscribe(aAvailable => this.timetrackingEnabled = aAvailable);
    this.operatorService.load(this.activatedRoute.snapshot.params[OPERATOR_ID_ROUTE_PARAM]).subscribe(d => {
      this.operator = d;
      if (this.operator.logoId) {
        this.fileDataService.getScaledImage(this.operator.logoId, {aWidth: 100, aHeight: 100}).subscribe(fileData => {
          this.operatorLogo = fileData;
        }, error => {
          console.error('Could not load operator logo');
          console.error(error);
        });
      }
      if (this.operator.systemPlan && this.operator.systemPlan.pictureId) {
        this.fileDataService.getScaledImage(this.operator.systemPlan.pictureId, {aWidth: 100, aHeight: 100}).subscribe(fileData => {
          this.operatorSystemPlan = fileData;
        }, error => {
          console.error('Could not load operator system plan');
          console.error(error);
        });
      }
      this.sections = StructInfoDocSectionVM.convertSectionsToVM(this.operator.infoDoc.sections, []);
    });
    this.operatorService.getStructures(this.activatedRoute.snapshot.params[OPERATOR_ID_ROUTE_PARAM]).subscribe(d => {
      this.structures = d.sort((a, b) => a.name.localeCompare(b.name));
    });
  }

  private createOperator() {
    const operator = JsonOperator.empty();
    operator.dbId = Utilities.getUUID();
    this.setDefaultSections(operator);
    this.operator = operator;
  }

  private setDefaultSections(aOperator: JsonOperator) {
    aOperator.infoDoc.sections = [
      JsonStructureInfoDocumentSection.create('Berichte'),
      JsonStructureInfoDocumentSection.create('Wasserrechtliche Erlaubnisse'),
      JsonStructureInfoDocumentSection.create('Strangbetrachtung')
    ];
  }
}
