import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Utilities} from 'rueb-data-model';
import {environment} from '../../environments/environment';
import {TypedJSON} from 'typedjson';
import {JsonTask} from 'rueb-data-model/src/json-task';
import {JsonFormSection} from 'rueb-data-model/src/json-form-section';


/*
  Generated class for the RestProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
export const STRUCT_LINK_URL = 'structLink';
export const PROTOCOL_URL = 'protocol';

const BASIC_URL = 'rb-backend/rest/';

const REST_CONFIG_KEY = 'restConfig';

interface RestConfig {
  host: string;
  user: string;
  pass: string;
  authenticate: boolean;
}

export interface GetParams {
  id?: string;
  appendGet?: boolean;
  url?: string;
  params?: {};
}

@Injectable()
export class RestProvider {
  public static OPERATORS_URL = 'operator';
  public static STRUCT_URL = 'struct';
  public static USER_URL = 'user';
  public static FORM_URL = 'form';
  public static IMAGE_URL = 'image';
  public static FEATURE_URL = 'features';
  public static readonly TIMETRACKING_URL = 'timetracking';
  public readonly BASIC_URL;
  private readonly replicationAuthorization: string;
  private readonly restConfig: RestConfig;


  constructor(public http: HttpClient) {
    TypedJSON.setGlobalConfig({
      typeResolver: (sourceObject, knownTypes) => {
        const metaInfo: {} = sourceObject['meta'];
        let typeString: string;
        if (metaInfo != null) {
          typeString = sourceObject['meta']['type'];
        }
        return knownTypes.get(typeString);
      },
      knownTypes: [JsonTask, JsonFormSection],
      nameResolver: ctor => {
        switch (ctor) {
          case JsonTask:
            return 'JsonTask';
          case JsonFormSection:
            return 'JsonFormSection';
          default:
            return ctor.name;
        }
      },
      typeHintEmitter: () => {
      } // do nothing as type hints are already encoded in meta info
    });

    this.restConfig = environment.restConfig;

    if (this.restConfig != null) {
      if (Utilities.notEmpty(this.restConfig.host)) {
        this.BASIC_URL = this.restConfig.host + (this.restConfig.host.endsWith('/') ? '' : '/') + BASIC_URL;
      } else {
        console.error('No rest host specified');
      }


      if (Utilities.notEmpty(this.restConfig.user) && Utilities.notEmpty(this.restConfig.pass)) {
        console.info('Encoding basic auth...');
        this.replicationAuthorization = btoa(this.restConfig.user + ':' + this.restConfig.pass);
        console.info('Encoded basic auth');
      } else {
        console.error('No authentication parameters specified');
      }
    } else {
      console.error('No rest config provided');
    }
  }

  putRemoteData(data: {}, url: string): Observable<Object> {
    let headers: HttpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json');

    if (this.restConfig != null && this.restConfig.authenticate) {
      headers = headers.set('Authorization', `Basic ${this.replicationAuthorization}`);
    }

    return this.http.put(this.BASIC_URL + url, data, {headers});
  }

  deleteRemoteData(id: string): Observable<Object> {
    let headers = new HttpHeaders();

    if (this.restConfig != null && this.restConfig.authenticate) {
      headers = headers.set('Authorization', `Basic ${this.replicationAuthorization}`);
    }

    return this.http.delete(this.BASIC_URL + 'delete/' + id, {headers});
  }

  deleteRemoteDataRest(aUrl: string): Observable<Object> {
    let headers = new HttpHeaders();

    if (this.restConfig != null && this.restConfig.authenticate) {
      headers = headers.set('Authorization', `Basic ${this.replicationAuthorization}`);
    }

    return this.http.delete(`${this.BASIC_URL}${aUrl}`, {headers});
  }

  getAllRemoteData(url: string): Observable<Object> {
    let headers = new HttpHeaders();

    if (this.restConfig != null && this.restConfig.authenticate) {
      headers = headers.set('Authorization', `Basic ${this.replicationAuthorization}`);
    }

    return this.http.get(this.BASIC_URL + url + '/all', {headers});
  }

  createGetUrl(aUrl: string, aParams?: {}): Observable<string> {
    const targetUrl = this.generateTargetUrl(aUrl, aParams);
    return Observable.of(targetUrl);
  }

  getRemoteData({id, appendGet = true, url, params}: GetParams): Observable<Object> {
    let headers = new HttpHeaders();

    if (this.restConfig != null && this.restConfig.authenticate) {
      headers = headers.set('Authorization', `Basic ${this.replicationAuthorization}`);
    }

    let finalUrl = this.BASIC_URL + (url != null ? url : '');
    if (appendGet) {
      finalUrl += 'get/';
    }
    if (id != null) {
      finalUrl += id;
      return this.http.get(finalUrl, {headers: headers, responseType: 'text', params: params});
    } else {
      return this.http.get(finalUrl, {params: params, headers: headers});
    }
  }

  postRemoteBlob(data: {}, url: string, params: {}): Observable<Blob> {
    let headers: HttpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json');

    if (this.restConfig != null && this.restConfig.authenticate) {
      headers = headers.set('Authorization', `Basic ${this.replicationAuthorization}`);
    }

    return this.http.post(this.BASIC_URL + url, data, {headers, params: params, responseType: 'blob'});
  }

  query(url: string, payload?: any): Observable<any> {
    let headers = new HttpHeaders();

    if (this.restConfig != null && this.restConfig.authenticate) {
      headers = headers.set('Authorization', `Basic ${this.replicationAuthorization}`);
    }
    if (payload) {
      return this.http.post(this.BASIC_URL + url, payload, {headers});
    } else {
      return this.http.get(this.BASIC_URL + url, {headers});
    }
  }

  login(credentials: {}): Observable<Object> {
    return this.http.post(`${this.BASIC_URL}login`, {login: credentials['login'], password: credentials['password']});
  }

  getRoleHint(): Observable<{}> {
    const host = environment.restConfig.host;
    return this.http.get(`${this.BASIC_URL}login`);
  }

  private generateTargetUrl(aUrl: string, aParams?: {}): string {
    let finalUrl = this.BASIC_URL + (aUrl != null ? aUrl : '');

    if (aParams) {
      finalUrl += '?' + Utilities.serializeQueryParameters(aParams);
    }

    return finalUrl;
  }
}
