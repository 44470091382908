import {Injectable} from '@angular/core';
import {NavigationService} from '../navigation.service';
import {MenuItem} from 'primeng/api';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable()
export class NavUsersCreateGuard implements Resolve<MenuItem[]> {


  constructor(private navigationService: NavigationService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MenuItem[]> | Promise<MenuItem[]> | MenuItem[] {
    this.navigationService.clearItems();
    const items = [
      {label: 'Benutzer', routerLink: ['/users']},
      {label: 'Neuer Benutzer'}
    ];
    this.navigationService.addItems(...items);
    return items;
  }


}
