import {Component, Input} from '@angular/core';
import {JsonOperator, JsonStructure, JsonStructureInfo, PlaceholderSections} from 'rueb-data-model';
import {DisplayStructureInfoService} from '../../pages/display-structure-info/service/display-structure-info.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-struct-info-accordion',
  templateUrl: 'struct-info-accordion.component.html',
  styleUrls: ['struct-info-accordion.component.css']
})
export class StructInfoAccordionComponent {
  @Input()
  structInfoSection: InfoSummarySectionVM;

  placeholderSections = PlaceholderSections;

  constructor(private displayInfoService: DisplayStructureInfoService,
              private router: Router) {
  }

  getSubSections(): InfoSummarySectionVM[] {
    if (this.structInfoSection && this.structInfoSection.subSections) {
      return InfoSummarySectionVM.getSubSectionsAsList(this.structInfoSection.subSections);
    }
    return [];
  }

  displayInfo(aInfo: JsonStructureInfo) {
    this.displayInfoService.infos = this.structInfoSection.infos.map(aCurInfo => {
      return {dataId: aCurInfo.dataId, filename: aCurInfo.name};
    });
    // TODO replace dummy with structure id
    this.router.navigate(this.structInfoSection.getRouterCommands().concat([aInfo.dataId, {filename: aInfo.name}]));
  }

}

export abstract class InfoSummarySectionVM {
  label: String;
  subSections: Map<String, InfoSummarySectionVM> = new Map<String, InfoSummarySectionVM>();
  infos: JsonStructureInfo[] = [];
  placeholderFor: string;
  placeholderData: any;

  static getSubSectionsAsList(aSubSections: Map<String, InfoSummarySectionVM>): InfoSummarySectionVM[] {
    const subSectionList: InfoSummarySectionVM[] = [];
    aSubSections.forEach(value => subSectionList.push(value));
    return subSectionList.sort((a, b) => a.label.localeCompare(b.label as string));
  }

  abstract getRouterCommands(): any[];
}

export class StructInfoSummarySectionVM extends InfoSummarySectionVM {
  structure: JsonStructure;

  getRouterCommands(): any[] {
    return ['/structures', this.structure.dbId, 'infos'];
  }
}

export class OperatorInfoSummarySectionVM extends InfoSummarySectionVM {
  operator: JsonOperator;

  getRouterCommands(): any[] {
    return ['/operators', this.operator.dbId, 'infos'];
  }
}
