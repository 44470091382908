import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormCreatePageComponent} from './formcreate';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormComponentsModule} from '../../forms-components/form-components.module';
import {SuiModule} from 'ng2-semantic-ui';
import {EditTaskModalComponent} from './edit-task-modal/edit-task-modal';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {EditComponent} from './edit-component/edit-component';
import {FormItemComponent} from './form-item-component/form-item-component';

@NgModule({
  declarations: [
    FormCreatePageComponent,
    EditTaskModalComponent,
    EditComponent,
    FormItemComponent,
  ],
  imports: [
    FormComponentsModule,
    FormsModule,
    CommonModule,
    RouterModule,
    SuiModule,
    BrowserAnimationsModule,
  ],
  entryComponents: [
    EditTaskModalComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class FormcreatePageModule {
}
